import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import icDate from '../../../assets/images/Date.svg';
import icClose from '../../../assets/images/new/cancel.svg';
import icArrow from '../../../assets/images/new/dropdown.svg';
import icArrow1 from '../../../assets/images/new/dropdown2.svg';
import icArrowOpen from '../../../assets/images/new/dropdown-open.svg';
import icArrowOpen1 from '../../../assets/images/new/dropdown-up.svg';
import icCheckbox from '../../../assets/images/new/checkbox.svg';
import icCheckbox1 from '../../../assets/images/new/checkbox-1.svg';
import icRadio from '../../../assets/images/new/radio.svg';
import icRadio1 from '../../../assets/images/new/radio-1.svg';
import icToggle from '../../../assets/images/new/toggle.svg';
import icToggle1 from '../../../assets/images/new/toggle-1.svg';
import styles from './inputs.module.css';
import eye1 from '../../../assets/images/icons-solid-eye-off.svg';
import eye from '../../../assets/images/icons-solid-eye-on.svg';

function GeneralDateRangePicker({
  formStyle, setDate = function () {}, startDate, endDate, minDate, maxDate, placeHolder,
}) {
  const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz'];
  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'dd.mm.yyyy',
    },
  };
  const selected = () => (startDate === null && endDate === null);
  return (
    <div className={`${styles.main} ${!selected() && styles.selected} ${formStyle}`}>
      {selected()
        && <div className={styles.pickerPlaceHolder}>{placeHolder}</div>}
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(e) => setDate(e)}
        showMonthDropdown
        locale={locale}
        showYearDropdown
        dateFormat="dd.MM.yy"
        dropdownMode="select"
        className={`${styles.datePicker} ${!selected() && styles.selected}`}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      {selected()
        ? <img alt="date" src={icDate} />
        : (
          <div className="d-flex c-pointer" onClick={() => setDate([null, null])}>
            <img alt="date" src={icClose} />
          </div>
        )}
    </div>
  );
}

function GeneralMultipleDropdown({
  formStyle, dataList = [], selected = [], placeHolder, setSelected = function () {}, isPassive, isLight, search,
}) {
  const [open, setOpen] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    setFilteredList(dataList);
  }, [dataList]);
  useEffect(() => {
    if (!open) {
      setSearchWord('');
    }
  }, [open]);
  useEffect(() => {
    if (searchWord === '') {
      setFilteredList(dataList);
    } else {
      const newList = dataList.filter((e) => e.value.toLowerCase().includes(searchWord.toLowerCase()));
      setFilteredList(newList);
    }
  }, [searchWord]);
  function handleSelection(item) {
    const arr = [...selected];
    const index = arr.findIndex((k) => k.key === item.key);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(item);
    }
    setSelected(arr);
  }
  return (
    <div className={`d-flex f-column p-relative ${formStyle}`}>
      <div
        className={`${isLight ? styles.lightDropdown : styles.multipleDropdown} ${(open || selected.length > 0) && styles.open}`}
        onClick={(event) => {
          if (!isPassive && !(search && open)) {
            setOpen(!open);
          }
        }}
      >
        {(search && open) ? (
          <div className="d-flex w100">
            <input
              placeholder="Ara ..."
              className={`${styles.inputStyle2} ${styles.darkText}  `}
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
            />
            <div
              className="f1 d-flex justify-end"
              onClick={(event) => {
                if (!isPassive) {
                  setOpen(!open);
                }
              }}
            >
              <img alt="select" src={icArrowOpen1} className="selection-none" />
            </div>
          </div>
        ) : (
          <>
            <div className={`${isLight ? styles.lightDropdownTitle : styles.multipleDropdownTitle} ${(open || selected.length > 0) && styles.open}`}>
              {placeHolder}
              {' '}
              {selected.length > 0 && `(${selected.length})`}
            </div>
            {
                isLight
                  ? (
                    <>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      <img alt="select" src={(open || selected.length > 0) ? icArrowOpen1 : selected ? icArrow : icArrow} className="selection-none" />
                    </>
                  )
                  : (
                    <>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      <img alt="select" src={(open || selected.length > 0) ? icArrowOpen : icArrow} className="selection-none" />
                    </>
                  )
              }
          </>
        )}
      </div>
      {open && (
      <div className={styles.multipleDropdownList}>
        {filteredList.map((e, i) => (
          <div key={i} className={isLight ? styles.lightDropdownListItem : styles.multipleDropdownListItem} onClick={() => handleSelection(e)}>
            <img alt="check" src={(selected.findIndex((item) => item.key === e.key) > -1) ? icCheckbox : icCheckbox1} />
            <div className="m-l-8">{e.value}</div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
}
function GeneralDropdown({
  formStyle, dataList = [], selected, title, placeHolder, setSelected = function () {}, isPassive, isLight, search,
}) {
  const [open, setOpen] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    setFilteredList(dataList);
  }, [dataList]);
  useEffect(() => {
    if (!open) {
      setSearchWord('');
    }
  }, [open]);
  useEffect(() => {
    if (searchWord === '') {
      setFilteredList(dataList);
    } else {
      const newList = dataList.filter((e) => String(e.value).toLowerCase().includes(searchWord.toLowerCase()));
      setFilteredList(newList);
    }
  }, [searchWord]);
  function handleSelection(item) {
    setOpen(false);
    setSelected(selected?.key === item?.key ? null : item);
  }
  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      window.addEventListener('click', (evt) => {
        setOpen(false);
      }, false);
    }
    currentRef.current = false;
  }, []);
  function handleClick(event) {
    setTimeout(() => {
      setOpen(!open);
    }, 50);
  }
  return (
    <div className={`d-flex f-column p-relative ${formStyle}`}>
      <div className={`${styles.title}  ${isPassive && styles.passive}`}>{!!selected && title}</div>
      <div
        className={`${isLight ? styles.lightDropdown : styles.multipleDropdown} ${(open || !!selected) && styles.open}`}
        onClick={(event) => {
          if (!isPassive && !(search && open)) {
            handleClick(event);
          }
        }}
      >
        {(search && open) ? (
          <div className="d-flex w100">
            <input
              placeholder="Ara ..."
              className={`${styles.inputStyle2} ${styles.darkText}  `}
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
            />
            <div
              className="f1 d-flex justify-end"
              onClick={(event) => {
                if (!isPassive) {
                  setOpen(!open);
                }
              }}
            >
              <img alt="select" src={icArrowOpen1} className="selection-none" />
            </div>
          </div>
        ) : (
          <>
            <div className={`${isLight ? styles.lightDropdownTitle : styles.multipleDropdownTitle} ${(open || !!selected) && styles.open} ${isPassive && styles.passive}`}>{selected?.value || placeHolder}</div>
            {
              isLight
                ? (
                  <>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    <img alt="select" src={(open) ? icArrowOpen1 : selected ? icArrow : icArrow} className="selection-none" />
                  </>
                )
                : (
                  <>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    <img alt="select" src={(open) ? icArrowOpen : selected ? icArrow1 : icArrow} className="selection-none" />
                  </>
                )
            }
          </>
        )}
      </div>
      {open && (
      <div className={`${styles.multipleDropdownList} m-t-16`}>
        {filteredList.map((e, i) => (
          <div key={i} className={isLight ? styles.lightDropdownListItem : styles.multipleDropdownListItem} onClick={() => handleSelection(e)}>
            <img alt="check" src={selected?.key === e.key ? icRadio : icRadio1} />
            <div className="m-l-8">{e.value}</div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
}

function GeneralInput({
  maxLength,
  onBlur = function () {},
  formStyle,
  rightIcon,
  placeHolder,
  value,
  onChange = function () {},
  title,
  darkText,
  price,
  pennyOnChange,
  pennyValue,
  inputStyle,
  textArea,
  isPassive,
  inputType,
}) {
  const [secureOn, setSecureOn] = useState(false);
  useEffect(() => {
    setSecureOn(inputType === 'password');
  }, [inputType]);
  return (
    <div className={`d-flex f-column p-relative ${formStyle}`}>
      <div className={`${styles.title} ${isPassive && styles.passive}`}>{(value !== '' || price) && title}</div>
      <div className={`${styles.inputForm}`}>
        {textArea ? (
          <textarea
            maxLength={maxLength}
            onBlurCapture={() => onBlur()}
            placeholder={placeHolder || ''}
            className={`${styles.inputStyle} ${styles.inputTextArea} ${darkText && styles.darkText}  ${price ? 'w50' : 'w100'} ${inputStyle} ${price && 'text-right'}`}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        )
          : (
            <input
              maxLength={maxLength}
              onBlurCapture={() => onBlur()}
              placeholder={placeHolder || ''}
              className={`${styles.inputStyle} ${darkText && styles.darkText}  ${price ? 'w50' : 'w100'} ${inputStyle} ${price && 'text-right'}  ${isPassive && styles.passive}`}
              value={value}
              type={secureOn ? 'password' : 'text'}
              onChange={(e) => onChange(e.target.value)}
            />
          )}

        <div className="d-flex align-center f1">
          {price
              && (
              <div className={styles.priceComma}>,</div>
              )}
          {price
              && (
              <input
                className={`${styles.inputStyle} ${'w50'} ${inputStyle}`}
                onChange={(e) => pennyOnChange(e.target.value)}
                placeholder="00"
                value={pennyValue}
                maxLength={2}
              />
              )}
        </div>
        {rightIcon && (
          <img alt="text" src={rightIcon} />
        )}
        {inputType === 'password' && (
        <div className="c-pointer d-flex align-center justify-center" onClick={() => setSecureOn(!secureOn)}>
          <img src={secureOn ? eye1 : eye} alt="icon" />
        </div>
        )}
      </div>
    </div>
  );
}
function GeneralDatePicker({
  formStyle, value, onChange = function () {}, title, minDate, maxDate, darkText, placeHolder,
}) {
  const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz'];
  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'dd.mm.yyyy',
    },
  };
  return (
    <div className={`d-flex f-column p-relative ${formStyle}`}>
      <div className={styles.title}>{value !== '' && title}</div>
      <div className={`${styles.datePicker1}`}>
        {(value === null && placeHolder) && <div className={styles.pickerPlaceHolder}>{placeHolder}</div>}
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          selected={value}
          onChange={(e) => onChange(e)}
          showMonthDropdown
          locale={locale}
          showYearDropdown
          dateFormat="dd.MM.yy"
          dropdownMode="select"
          className={`${styles.datePicker} ${darkText && styles.darkText}`}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </div>
  );
}

function SmallSwitch({ formStyle, value, toggle = function () {} }) {
  return (
    <div className={`${styles.switch} ${formStyle}`} onClick={() => toggle()}>
      <img alt="switch" src={value ? icToggle1 : icToggle} />
    </div>
  );
}
export {
  GeneralDateRangePicker,
  GeneralMultipleDropdown,
  GeneralDropdown,
  GeneralInput,
  SmallSwitch,
  GeneralDatePicker,
};
