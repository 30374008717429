import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getJsonData,
  jsonData, searchAllMerchants, sms,
} from '../../../helpers/dataManager';
import applicationEnv from '../../../helpers/envManager';
import { sessionStorageKeys } from '../../../helpers/constant';
import { addMinutes, convertLocalToUTCDateTime } from '../../../utils/formatter';
import { GeneralButton } from '../../views/buttons/buttons';
import BaseView from '../../views/baseView/baseView';

function Result() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState(state?.orderNumber);
  const [reSendCount, setReSendCount] = useState(1);
  const callRef = useRef(false);
  const timeOut = useRef(null);
  useEffect(() => {
    if (!callRef.current) {
      sendSms(state?.url, orderNumber);
    }
    callRef.current = true;
    return () => clearTimeout(timeOut.current);
  }, []);
  async function getIntervalTime(order) {
    const env = await applicationEnv();
    const { statusInterval } = env;
    checkLoanStatus(statusInterval, order);
  }
  function checkLoanStatus(statusInterval, order) {
    clearTimeout(timeOut.current);
    const merchantId = sessionStorage.getItem(sessionStorageKeys.merchantId);
    getJsonData({ jsonKey: state?.jsonKey })
      .then((res) => {
        if (res?.expire) {
          navigate('/fail', { state: { ...state, reSendCount } });
        } else {
          searchAllMerchants({
            orderId: order,
            merchants: [merchantId],
          })
            .then((res) => {
              if (Array.isArray(res?.content) && res?.content.length > 0 && String(res?.content[0]?.applicationStatusCode) === '11') {
                navigate('/success');
              } else if (Array.isArray(res?.content) && res?.content.length > 0 && String(res?.content[0]?.applicationStatusCode) === '7') {
                navigate('/fail', { state: { ...state, reSendCount } });
              } else {
                timeOut.current = setTimeout(() => {
                  checkLoanStatus(statusInterval, order);
                }, statusInterval);
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  }
  function sendSms(url, order) {
    sms({
      gsm: state?.gsm,
      redirectionUrl: url,
    })
      .then((res) => {
        getIntervalTime(order).then(() => {}).catch(() => {});
      })
      .catch((err) => {
        if (err?.message === 'otpTimeout') {
          sendSms(url, order);
        }
      });
  }
  async function loanApp() {
    const env = await applicationEnv();
    const { createOrderUrl } = env;
    const expireDate = convertLocalToUTCDateTime(addMinutes(new Date(), -160));
    const { body } = state;
    const newOrderNumber = `${state.orderNumber}-${reSendCount}`;
    setOrderNumber(newOrderNumber);
    body.expireDate = expireDate;
    body.orderNumber = newOrderNumber;
    jsonData(body)
      .then((res) => {
        const newUrl = `${createOrderUrl}/hostedpayment?code=${res.jsonKey}`;
        sendSms(newUrl, newOrderNumber);
      })
      .catch(() => {
      });
    setReSendCount(reSendCount + 1);
  }
  return (
    <BaseView>
      <div className="d-flex f-column align-center">
        <div className="title1 text-center">Sipariş başarıyla oluşturuldu.</div>
        <div className="t-regular m-t-16 m-r-16 m-l-16 text-center c-dark-mid-grey">
          Kredi başvurusunun devamı için müşteriye SMS linki
          iletildi.
        </div>
        <div className="d-flex f-column f1 m-t-100">
          <div className="loader" />
        </div>
        <GeneralButton buttonTitle="Linki Tekrar Gönder" buttonStyle="m-t-30 w-200" buttonClick={() => loanApp()} />
      </div>
    </BaseView>
  );
}

export default Result;
