import React, { useEffect, useRef, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import styles from './financials.module.css';
import icExcel from '../../../assets/images/new/excel.svg';
import {
  GeneralDatePicker, GeneralDropdown, GeneralInput,
} from '../../views/newInputs/inputs';
import icSearch from '../../../assets/images/new/search.svg';
import icPlus from '../../../assets/images/new/plus.svg';
import icDetail from '../../../assets/images/new/detail.svg';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import icDownload from '../../../assets/images/new/download.svg';
import icEdit from '../../../assets/images/new/edit.svg';
import icCancel2 from '../../../assets/images/new/cancel2.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralButton } from '../../views/buttons/buttons';
import {
  createBank, createPartner,
  getBanksWithContact, getParameter, partnerList, updateBank, updatePartner,
} from '../../../helpers/dataManager';
import { creditTypes, creditTypesEnum } from '../../../helpers/constant';

function Financials() {
  // General
  const [selectedSection, setSelectedSection] = useState(1);
  const [financialSearch, setFinancialSearch] = useState('');
  const [partnerSearch, setPartnerSearch] = useState('');
  const [financialShowModal, setFinancialShowModal] = useState(false);
  const [financialEditModal, setFinancialEditModal] = useState(false);
  const [partnerShowModal, setPartnerShowModal] = useState(false);
  const [partnerEditModal, setPartnerEditModal] = useState(false);
  //-------------------
  // Dropdown Lists
  const [firmTypeList, setFirmTypeList] = useState([]);
  const [commissionTypeList, setCommissionTypeList] = useState([]);
  const [apiTypeList, setApiTypeList] = useState([]);
  const [partnerTypeList, setPartnerTypeList] = useState([]);

  //-------------------
  // Dropdown Data
  const [selectedFinancial, setSelectedFinancial] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  //-------------------
  // Data List
  const [allFinancials, setAllFinancials] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [allPartners, setAllPartners] = useState([]);
  const [partners, setPartners] = useState([]);
  //-------------------
  function filterFinancials(word) {
    setFinancialSearch(word);
    if (word.length < 3) {
      setFinancials(allFinancials);
    } else {
      const arr = allFinancials.filter((e) => e.bank?.firmType?.value.toLowerCase().includes(word.toLowerCase())
          || (e.bank?.bankName || '').toLowerCase().includes(word.toLowerCase())
          || (e.contacts[0]?.gsm || '').toLowerCase().includes(word.toLowerCase())
          || (`${e?.contacts[0]?.name || ''} ${e?.contacts[0]?.surname || ''}`).toLowerCase().includes(word.toLowerCase())
          || (e.bank?.vkn || '').toLowerCase().includes(word.toLowerCase()));
      setFinancials(arr);
    }
  }
  function filterPartners(word) {
    setPartnerSearch(word);
    if (word.length < 3) {
      setPartners(allPartners);
    } else {
      const arr = allPartners.filter((e) => e.partner?.partnerType?.value.toLowerCase().includes(word.toLowerCase())
          || (e.partner?.title || '').toLowerCase().includes(word.toLowerCase())
          || (e.contacts[0]?.gsm || '').toLowerCase().includes(word.toLowerCase())
          || (`${e?.contacts[0]?.name || ''} ${e?.contacts[0]?.surname || ''}`).toLowerCase().includes(word.toLowerCase())
          || (e.partner?.tax || '').toLowerCase().includes(word.toLowerCase()));
      setPartners(arr);
    }
  }
  function renderSections() {
    switch (selectedSection) {
      case 1:
        return (
          <>
            <div className="d-flex align-center m-t-24">
              <GeneralInput
                value={financialSearch}
                rightIcon={icSearch}
                onChange={(e) => filterFinancials(e)}
                formStyle={styles.searchBar}
                placeHolder="Firma türü, unvan, vergi numarası, yetkili ad soyad, yetkili telefon..."
              />
              <div
                className={styles.btn}
                onClick={() => {
                  setSelectedFinancial(JSON.parse(JSON.stringify(bankModel)));
                  setFinancialEditModal(true);
                }}
              >
                Finansal kurum ekle
                <img alt="plus" src={icPlus} className="m-l-8" />
              </div>
            </div>
            <div className="d-flex f-column m-t-24">
              <div className={styles.tableTitle}>
                <div>Kurum Tipi</div>
                <div>Ünvan</div>
                <div>Kredi Tipi</div>
                <div>Vergi numarası</div>
                <div>Komisyon oranı</div>
                <div>Yetkili ad soyad</div>
                <div>Yetkili telefon</div>
                <div />
              </div>
              <div className={styles.tableContent}>
                {financials.map((item, index) => (
                  <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                    <div>{item?.bank?.firmType?.value}</div>
                    <div>{item?.bank?.bankName}</div>
                    <div>{item?.bank?.apiType?.value}</div>
                    <div>{item?.bank?.vkn}</div>
                    <div>{item?.bank?.commissionType?.value === 'Fixed' ? `${item?.bank?.commissionRate} TL` : `%${item?.bank?.commissionRate}`}</div>
                    <div>{`${item?.contacts[0]?.name || ''} ${item?.contacts[0]?.surname || ''}`}</div>
                    <div>{item?.contacts[0]?.gsm || ''}</div>
                    <div>
                      <div className="d-flex align-center">
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setSelectedFinancial(JSON.parse(JSON.stringify(item)));
                            setFinancialShowModal(true);
                          }}
                        >
                          <img alt="dot" src={icDetail} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="d-flex align-center m-t-24">
              <GeneralInput
                value={partnerSearch}
                rightIcon={icSearch}
                onChange={(e) => filterPartners(e)}
                formStyle={styles.searchBar}
                placeHolder="Firma türü, unvan, vergi numarası, yetkili ad soyad, yetkili telefon..."
              />
              <div
                className={styles.btn}
                onClick={() => {
                  setSelectedPartner(JSON.parse(JSON.stringify(partnerModel)));
                  setPartnerEditModal(true);
                }}
              >
                İş ortağı ekle
                <img alt="plus" src={icPlus} className="m-l-8" />
              </div>
            </div>
            <div className="d-flex f-column m-t-24">
              <div className={styles.tableTitle}>
                <div>Firma türü</div>
                <div>Unvan</div>
                <div>Vergi numarası</div>
                <div>Komisyon oranı</div>
                <div>Yetkili ad soyad</div>
                <div>Yetkili telefon</div>
                <div />
              </div>
              <div className={styles.tableContent}>
                {partners.map((item, index) => (
                  <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                    <div>{item?.partner?.partnerType?.value}</div>
                    <div>{item?.partner?.title}</div>
                    <div>{item?.partner?.tax}</div>
                    <div>{item?.partner?.commissionType?.value === 'Fixed' ? `${item?.partner?.commissionRate} TL` : `%${item?.partner?.commissionRate}`}</div>
                    <div>{`${item?.contacts[0]?.name || ''} ${item?.contacts[0]?.surname || ''}`}</div>
                    <div>{item?.contacts[0]?.gsm || ''}</div>
                    <div>
                      <div className="d-flex align-center">
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setSelectedPartner(JSON.parse(JSON.stringify(item)));
                            setPartnerShowModal(true);
                          }}
                        >
                          <img alt="dot" src={icDetail} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </>
        );
      default:
        return <div />;
    }
  }

  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      getBankList();
      getParameterData();
      getPartnerList();
    }
    currentRef.current = false;
  }, []);
  function getParameterData() {
    getParameter({ parameter: 'FirmType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setFirmTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'CommissionType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setCommissionTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'ApiType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setApiTypeList(a);
      })
      .catch(() => {});
    getParameter({ parameter: 'PartnerType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
        setPartnerTypeList(a);
      })
      .catch(() => {});
  }

  function getBankList() {
    getBanksWithContact()
      .then((res) => {
        setFinancials(res);
        setAllFinancials(res);
      })
      .catch(() => {});
  }
  function getPartnerList() {
    partnerList()
      .then((res) => {
        setPartners(res);
        setAllPartners(res);
      })
      .catch(() => {});
  }
  function handleFinancialDataChange(key1, key2, value, index) {
    if (key1 === 'contacts') {
      const a = { ...selectedFinancial };
      if (Array.isArray(a[key1]) && a[key1].length > 0) {
        a[key1][index][key2] = value;
      } else {
        a[key1][index] = { [key2]: value };
      }
      setSelectedFinancial(a);
    } else {
      const a = { ...selectedFinancial };
      a[key1][key2] = value;
      setSelectedFinancial(a);
    }
  }
  function handlePartnerDataChange(key1, key2, value, index) {
    if (key1 === 'contacts') {
      const a = { ...selectedPartner };
      if (Array.isArray(a[key1]) && a[key1].length > 0) {
        a[key1][index][key2] = value;
      } else {
        a[key1][index] = { [key2]: value };
      }
      setSelectedPartner(a);
    } else {
      const a = { ...selectedPartner };
      a[key1][key2] = value;
      setSelectedPartner(a);
    }
  }
  function saveBank() {
    setFinancialEditModal(false);
    const data = { ...selectedFinancial?.bank, contacts: selectedFinancial?.contacts };
    if (selectedFinancial?.bank?.id === undefined) {
      createBank({ data })
        .then((res) => {
          setSelectedFinancial(null);
          getBankList();
        })
        .catch(() => {});
    } else {
      updateBank({ data, id: selectedFinancial?.bank?.id })
        .then((res) => {
          setSelectedFinancial(null);
          getBankList();
        })
        .catch(() => {});
    }
  }
  function savePartner() {
    setPartnerEditModal(false);
    const data = { ...selectedPartner?.partner, contacts: selectedPartner?.contacts };
    if (selectedPartner?.partner?.id === undefined) {
      createPartner({ data })
        .then((res) => {
          setSelectedPartner(null);
          getPartnerList();
        })
        .catch(() => {});
    } else {
      updatePartner({ data, id: selectedPartner?.partner?.id })
        .then((res) => {
          setSelectedPartner(null);
          getPartnerList();
        })
        .catch(() => {});
    }
  }
  return (
    <BaseView>
      <GeneralModal
        visible={financialEditModal}
        toggle={() => {
          setFinancialEditModal(false);
          setSelectedFinancial(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Finansal Kurum Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setFinancialEditModal(false);
                setSelectedFinancial(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1 m-r-32">
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Firma Türü"
                  placeHolder="Firma Türü"
                  dataList={firmTypeList}
                  formStyle="z1"
                  selected={{
                    key: selectedFinancial?.bank?.firmType?.id,
                    value: selectedFinancial?.bank?.firmType?.value,
                  }}
                  setSelected={(e) => handleFinancialDataChange('bank', 'firmType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.bank?.bankName}
                  onChange={(e) => handleFinancialDataChange('bank', 'bankName', e)}
                  placeHolder="Unvan"
                  title="Unvan"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.bank?.vkn}
                  onChange={(e) => handleFinancialDataChange('bank', 'vkn', e)}
                  placeHolder="Vergi numarası"
                  title="Vergi numarası"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.contacts[0]?.name || ''}
                  onChange={(e) => handleFinancialDataChange('contacts', 'name', e, 0)}
                  placeHolder="Yetkili ad"
                  title="Yetkili ad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.contacts[0]?.surname || ''}
                  onChange={(e) => handleFinancialDataChange('contacts', 'surname', e, 0)}
                  placeHolder="Yetkili soyad"
                  title="Yetkili soyad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.contacts[0]?.gsm || ''}
                  onChange={(e) => handleFinancialDataChange('contacts', 'gsm', e, 0)}
                  placeHolder="Yetkili telefon"
                  title="Yetkili telefon"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.contacts[0]?.email || ''}
                  onChange={(e) => handleFinancialDataChange('contacts', 'email', e, 0)}
                  placeHolder="Yetkili e-mail"
                  title="Yetkili e-mail"
                />
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8 z1">
                <GeneralDatePicker
                  formStyle="z1"
                  darkText
                  value={selectedFinancial?.bank?.agreementDate}
                  onChange={(e) => handleFinancialDataChange('bank', 'agreementDate', e.toISOString())}
                  title="Sözleşme imza tarihi"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.bank?.commissionRate}
                  onChange={(e) => handleFinancialDataChange('bank', 'commissionRate', e)}
                  placeHolder="Komisyon oranı"
                  title="Komisyon oranı"
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Komisyon türü"
                  placeHolder="Komisyon türü"
                  formStyle="z2"
                  dataList={commissionTypeList}
                  selected={{
                    key: selectedFinancial?.bank?.commissionType?.id,
                    value: selectedFinancial?.bank?.commissionType?.value,
                  }}
                  setSelected={(e) => handleFinancialDataChange('bank', 'commissionType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Kredi türü"
                  placeHolder="Kredi türü"
                  dataList={creditTypes}
                  formStyle="z3"
                  selected={{
                    key: selectedFinancial?.bank?.creditType,
                    value: creditTypesEnum[selectedFinancial?.bank?.creditType],
                  }}
                  setSelected={(e) => handleFinancialDataChange('bank', 'creditType', e.key)}
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="API türü"
                  placeHolder="API türü"
                  dataList={apiTypeList}
                  formStyle="z4"
                  selected={{
                    key: selectedFinancial?.bank?.apiType?.id,
                    value: selectedFinancial?.bank?.apiType?.value,
                  }}
                  setSelected={(e) => handleFinancialDataChange('bank', 'apiType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedFinancial?.bank?.address}
                  onChange={(e) => handleFinancialDataChange('bank', 'address', e)}
                  placeHolder="Adres"
                  title="Adres"
                />
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t4}>Dökümanlar</div>
                <GeneralButton buttonStyle="w100" buttonType="tertiary" buttonTitle="Dosya yükle" />
                <div className={styles.ct2}>
                  <div>Sözleşme_12312.pdf</div>
                  <div className="c-pointer">
                    <img alt="delete" src={icCancel2} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <GeneralInput
              darkText
              value={selectedFinancial?.bank?.freeText}
              onChange={(e) => handleFinancialDataChange('bank', 'freeText', e)}
              placeHolder="Notlar"
              title="Notlar"
            />
          </div>
          <div className="d-flex justify-center m-t-16">
            <GeneralButton buttonTitle="Kaydet" buttonStyle="w50" buttonClick={() => saveBank()} />
          </div>
        </div>
      </GeneralModal>
      <GeneralModal
        visible={financialShowModal}
        toggle={() => {
          setFinancialShowModal(false);
          setSelectedFinancial(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Finansal Kurum Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setFinancialShowModal(false);
                setSelectedFinancial(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Firma türü</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.firmType?.value}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Unvan</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.bankName}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Vergi numarası</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.vkn}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili ad soyad</div>
                <div className={styles.t2}>{`${selectedFinancial?.contacts[0]?.name || ''} ${selectedFinancial?.contacts[0]?.surname || ''}` }</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili telefon</div>
                <div className={styles.t2}>{selectedFinancial?.contacts[0]?.gsm || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili e-mail</div>
                <div className={styles.t2}>{selectedFinancial?.contacts[0]?.email || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Adres</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.address}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Notlar</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.freeText}</div>
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Sözleşme imza tarihi</div>
                <div
                  className={styles.t2}
                >
                  {selectedFinancial?.bank?.agreementDate ? selectedFinancial?.bank?.agreementDate.split('T')[0] : ''}
                </div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Komisyon oranı</div>
                <div
                  className={styles.t2}
                >
                  {selectedFinancial?.bank?.commissionType?.value === 'Fixed' ? `${selectedFinancial?.bank?.commissionRate} TL` : `%${selectedFinancial?.bank?.commissionRate}`}
                </div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>API türü</div>
                <div className={styles.t2}>{creditTypesEnum[selectedFinancial?.bank?.creditType]}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>API türü</div>
                <div className={styles.t2}>{selectedFinancial?.bank?.apiType?.value}</div>
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t1}>Dökümanlar</div>
                <div className={styles.t2}>????????</div>
                <div className={styles.t3}>
                  Dokümanları indir
                  <img alt="download" className="m-l-8" src={icDownload} />
                </div>
              </div> */}
            </div>
          </div>
          <div className="d-flex align-center justify-end m-t-16">
            <div
              className={`${styles.t3} c-pointer`}
              onClick={() => {
                setFinancialShowModal(false);
                setFinancialEditModal(true);
              }}
            >
              Bilgileri Düzenle
              <img alt="close" src={icEdit} className="m-l-8" />
            </div>
          </div>
        </div>
      </GeneralModal>
      <GeneralModal
        visible={partnerEditModal}
        toggle={() => {
          setPartnerEditModal(false);
          setSelectedPartner(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>İş Ortağı Detayları</div>
            <div
              className="c-pointer"
              onClick={() => {
                setPartnerEditModal(false);
                setSelectedPartner(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1 m-r-32">
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Firma Türü"
                  placeHolder="Firma Türü"
                  dataList={partnerTypeList}
                  formStyle="z1"
                  selected={{
                    key: selectedPartner?.partner?.partnerType?.id,
                    value: selectedPartner?.partner?.partnerType?.value,
                  }}
                  setSelected={(e) => handlePartnerDataChange('partner', 'partnerType', e?.parameter)}
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.partner?.title}
                  onChange={(e) => handlePartnerDataChange('partner', 'title', e)}
                  placeHolder="Unvan"
                  title="Unvan"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.partner?.tax}
                  onChange={(e) => handlePartnerDataChange('partner', 'tax', e)}
                  placeHolder="Vergi numarası"
                  title="Vergi numarası"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.contacts[0]?.name || ''}
                  onChange={(e) => handlePartnerDataChange('contacts', 'name', e, 0)}
                  placeHolder="Yetkili ad"
                  title="Yetkili ad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.contacts[0]?.surname || ''}
                  onChange={(e) => handlePartnerDataChange('contacts', 'surname', e, 0)}
                  placeHolder="Yetkili soyad"
                  title="Yetkili soyad"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.contacts[0]?.gsm || ''}
                  onChange={(e) => handlePartnerDataChange('contacts', 'gsm', e, 0)}
                  placeHolder="Yetkili telefon"
                  title="Yetkili telefon"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.contacts[0]?.email || ''}
                  onChange={(e) => handlePartnerDataChange('contacts', 'email', e, 0)}
                  placeHolder="Yetkili e-mail"
                  title="Yetkili e-mail"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.partner?.address}
                  onChange={(e) => handlePartnerDataChange('partner', 'address', e)}
                  placeHolder="Adres"
                  title="Adres"
                />
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8">
                <GeneralDatePicker
                  formStyle="z1"
                  darkText
                  value={selectedPartner?.partner?.agreementDate}
                  onChange={(e) => handlePartnerDataChange('partner', 'agreementDate', e.toISOString())}
                  title="Sözleşme imza tarihi"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  darkText
                  value={selectedPartner?.partner?.commissionRate}
                  onChange={(e) => handlePartnerDataChange('partner', 'commissionRate', e)}
                  placeHolder="Komisyon oranı"
                  title="Komisyon oranı"
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Komisyon türü"
                  placeHolder="Komisyon türü"
                  formStyle="z2"
                  dataList={commissionTypeList}
                  selected={{
                    key: selectedPartner?.partner?.commissionType?.id,
                    value: selectedPartner?.partner?.commissionType?.value,
                  }}
                  setSelected={(e) => handlePartnerDataChange('partner', 'commissionType', e?.parameter)}
                />
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t4}>Dokümanlar</div>
                <GeneralButton buttonStyle="w100" buttonType="tertiary" buttonTitle="Dosya yükle" />
                <div className={styles.ct2}>
                  <div>Sözleşme_12312.pdf</div>
                  <div className="c-pointer">
                    <img alt="delete" src={icCancel2} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <GeneralInput
              darkText
              value={selectedPartner?.partner?.freeText}
              onChange={(e) => handlePartnerDataChange('partner', 'freeText', e)}
              placeHolder="Notlar"
              title="Notlar"
            />
          </div>
          <div className="d-flex justify-center m-t-16">
            <GeneralButton buttonTitle="Kaydet" buttonStyle="w50" buttonClick={() => savePartner()} />
          </div>
        </div>
      </GeneralModal>
      <GeneralModal visible={partnerShowModal} toggle={() => setPartnerShowModal(false)}>
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>İş Ortağı Detayları</div>
            <div className="c-pointer" onClick={() => setPartnerShowModal(false)}>
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Firma türü</div>
                <div className={styles.t2}>{selectedPartner?.partner?.partnerType?.value}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Unvan</div>
                <div className={styles.t2}>{selectedPartner?.partner?.title}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Vergi numarası</div>
                <div className={styles.t2}>{selectedPartner?.partner?.tax}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili ad soyad</div>
                <div className={styles.t2}>{`${selectedPartner?.contacts[0]?.name || ''} ${selectedPartner?.contacts[0]?.surname || ''}`}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili telefon</div>
                <div className={styles.t2}>{selectedPartner?.contacts[0]?.gsm || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Yetkili e-mail</div>
                <div className={styles.t2}>{selectedPartner?.contacts[0]?.email || ''}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Adres</div>
                <div className={styles.t2}>{selectedPartner?.partner?.address}</div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Notlar</div>
                <div className={styles.t2}>{selectedPartner?.partner?.freeText}</div>
              </div>
            </div>
            <div className="f1">
              <div className="m-t-8">
                <div className={styles.t1}>Sözleşme imza tarihi</div>
                <div
                  className={styles.t2}
                >
                  {selectedPartner?.partner?.agreementDate ? selectedPartner?.partner?.agreementDate.split('T')[0] : ''}
                </div>
              </div>
              <div className="m-t-8">
                <div className={styles.t1}>Komisyon oranı</div>
                <div
                  className={styles.t2}
                >
                  {selectedPartner?.partner?.commissionType?.value === 'Fixed' ? `${selectedPartner?.partner?.commissionRate} TL` : `%${selectedPartner?.partner?.commissionRate}`}
                </div>
              </div>
              {/* <div className="m-t-8">
                <div className={styles.t1}>Dökümanlar</div>
                <div className={styles.t2}>3 adet doküman bulunmaktadır.</div>
                <div className={styles.t3}>
                  Dokümanları indir
                  <img alt="download" className="m-l-8" src={icDownload} />
                </div>
              </div> */}
            </div>
          </div>
          <div className="d-flex align-center justify-end m-t-16">
            <div
              className={`${styles.t3} c-pointer`}
              onClick={() => {
                setPartnerShowModal(false);
                setPartnerEditModal(true);
              }}
            >
              Bilgileri Düzenle
              <img alt="close" src={icEdit} className="m-l-8" />
            </div>
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex justify-space-between align-center">
          <div className={styles.c1}>
            <div
              className={`${styles.section} ${selectedSection === 1 && styles.selected}`}
              onClick={() => setSelectedSection(1)}
            >
              Finansal Kurumlar
            </div>
            <div
              className={`${styles.section} ${selectedSection === 2 && styles.selected}`}
              onClick={() => setSelectedSection(2)}
            >
              İş Ortakları
            </div>
          </div>
          {/* <img alt="excel" src={icExcel} className="c-pointer" /> */}
        </div>
        {renderSections()}
      </div>
    </BaseView>
  );
}
const bankModel = {
  bank: {
    firmType: null,
    name: '',
    vkn: '',
    commissionType: null,
    commissionRate: 10,
    bankCode: '',
    creditType: 'SHOPPING_CREDIT',
    address: '',
    freeText: '',
    agreementDate: '',
    apiType: null,
  },
  contacts: [
    {
      name: '',
      gsm: '',
      email: '',
      role: '',
      surname: '',
    },
  ],
};
const partnerModel = {
  partner: {
    partnerType: null,
    title: '',
    tax: '',
    address: '',
    agreementDate: null,
    commissionType: null,
    commissionRate: 10,
    freeText: '',
  },
  contacts: [
    {
      name: '',
      email: '',
      phone: '',
    },
  ],
};

export default Financials;
