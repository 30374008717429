import React, { useEffect, useRef, useState } from 'react';
import { value } from 'lodash/seq';
import BaseView from '../../views/baseView/baseView';
import icExcel from '../../../assets/images/new/excel.svg';
import {
  GeneralDropdown, GeneralInput,
} from '../../views/newInputs/inputs';
import icSearch from '../../../assets/images/new/search.svg';
import icPlus from '../../../assets/images/new/plus.svg';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import icEdit from '../../../assets/images/new/edit.svg';
import icDelete from '../../../assets/images/new/delete.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralButton } from '../../views/buttons/buttons';
import { debounce } from '../../../utils/debounce';
import {
  createUser, deleteUser, getAllUsers, stores, updateUser,
} from '../../../helpers/dataManager';
import Pagination from '../../views/pagination/pagination';
import icNoResults from '../../../assets/images/new/noUser.svg';
import { sessionStorageKeys, userRolesData1 } from '../../../helpers/constant';

function SuperMerchant({ styles }) {
  const pageSize = 20;
  const pageNumber = useRef(1);
  const [userSearch, setUserSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [toDeleteUser, setToDeleteUser] = useState(null);
  const [allUserData, setAllUserData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const currentRef = useRef(true);

  useEffect(() => {
    if (currentRef.current) {
      getStores();
    }
    currentRef.current = false;
  }, []);
  debounce(() => {
    pageNumber.current = 1;
    getUsers();
  }, [userSearch], 500);

  function getStores() {
    const id = sessionStorage.getItem(sessionStorageKeys.mainMerchantId);
    stores({ mainMerchantId: id })
      .then((e) => {
        const arr = e.map((e) => ({ merchant: e, key: e.id, value: e.merchantName }));
        setStoreList(arr);
      })
      .catch(() => {});
  }
  function getUsers() {
    getAllUsers({
      page: pageNumber.current - 1,
      query: userSearch,
      size: pageSize,
    })
      .then((res) => {
        const arr = res?.content.map((e) => ({ ...e, mainMerchantId: e?.merchant?.mainMerchantId }));
        setAllUserData({ ...res, content: arr });
      })
      .catch(() => {});
  }
  function handleUserDataChange(key, value) {
    const u = { ...selectedUser };
    u[key] = value;
    if (key === 'mainMerchantId') {
      u.merchant = null;
    }
    setSelectedUser(u);
  }
  function saveUser() {
    if (selectedUser?.id) {
      updateUser({ updatedRole: selectedUser?.role, userId: selectedUser?.id })
        .then((res) => {
          setSelectedUser(null);
          getUsers();
        })
        .catch(() => {});
    } else {
      createUser({ body: { ...selectedUser, merchantId: selectedUser?.merchant?.id } })
        .then((res) => {
          setSelectedUser(null);
          getUsers();
        })
        .catch(() => {});
    }
  }
  function handleDeleteUser() {
    deleteUser({ userId: toDeleteUser?.id })
      .then((res) => {
        setToDeleteUser(null);
        getUsers();
      })
      .catch(() => {});
  }
  return (
    <BaseView>
      <GeneralModal visible={selectedUser !== null} toggle={() => setSelectedUser(null)}>
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>{selectedUser?.id ? 'Kullanıcı düzenleme' : 'Yeni kullanıcı ekleme'}</div>
            <div className="c-pointer" onClick={() => setSelectedUser(null)}>
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            <div className="f1 m-r-32">
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.name}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('name', e);
                    }
                  }}
                  placeHolder="Ad*"
                  title="Ad*"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.surname}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('surname', e);
                    }
                  }}
                  placeHolder="Soyad*"
                  title="Soyad*"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.email}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('email', e);
                    }
                  }}
                  placeHolder="E-mail*"
                  title="E-mail*"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.gsm}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('gsm', e);
                    }
                  }}
                  placeHolder="Telefon*"
                  title="Telefon*"
                />
              </div>
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.userName}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('userName', e);
                    }
                  }}
                  placeHolder="Kullanıcı adı*"
                  title="Kullanıcı adı*"
                />
              </div>
              {!selectedUser?.id && (
              <div className="m-t-8">
                <GeneralInput
                  isPassive={selectedUser?.id}
                  darkText
                  value={selectedUser?.password}
                  onChange={(e) => {
                    if (!selectedUser?.id) {
                      handleUserDataChange('password', e);
                    }
                  }}
                  placeHolder="Şifre*"
                  title="Şifre*"
                />
              </div>
              )}
              <div className="m-t-8">
                <GeneralDropdown
                  isPassive={selectedUser?.id}
                  isLight
                  search
                  title="Bağlı bayi"
                  placeHolder="Bağlı bayi"
                  formStyle="z2"
                  dataList={storeList}
                  selected={{
                    key: selectedUser?.merchant?.id,
                    value: storeList.find((e) => e.key === selectedUser?.merchant?.id)?.value,
                  }}
                  setSelected={(e) => {
                    handleUserDataChange('merchant', e?.merchant);
                  }}
                />
              </div>
              <div className="m-t-8">
                <GeneralDropdown
                  isLight
                  title="Yetki türü"
                  placeHolder="Yetki türü"
                  dataList={userRolesData1}
                  selected={{
                    key: selectedUser?.role,
                    value: userRolesData1.find((e) => e.key === selectedUser?.role)?.value,
                  }}
                  setSelected={(e) => {
                    handleUserDataChange('role', e?.key);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.modalRequireText} m-t-16`}>
            *Zorunlu alanlar
          </div>
          <div className="d-flex justify-center m-t-16">
            <GeneralButton
              isGhost={selectedUser?.name === ''
                    || selectedUser?.surname === ''
                    || selectedUser?.email === ''
                    || selectedUser?.gsm === ''
                    || selectedUser?.userName === ''
                    || selectedUser?.password === ''
                    || !selectedUser?.role
                    || !selectedUser?.merchant}
              buttonTitle="Kaydet"
              buttonStyle="w50"
              buttonClick={() => saveUser()}
            />
          </div>
        </div>
      </GeneralModal>
      <GeneralModal visible={toDeleteUser !== null} toggle={() => setToDeleteUser(null)}>
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>{`${toDeleteUser?.name} ${toDeleteUser?.surname}`}</div>
            <div className="c-pointer" onClick={() => setToDeleteUser(null)}>
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex">
            adlı kullanıcıyı silmek istediğinize emin misiniz?
          </div>
          <div className="d-flex align-center m-t-16">
            <GeneralButton
              buttonType="tertiary"
              buttonTitle="Evet"
              buttonStyle="f1"
              buttonClick={() => handleDeleteUser()}
            />
            <GeneralButton
              buttonTitle="Hayır"
              buttonStyle="f1 m-l-24"
              buttonClick={() => setToDeleteUser(null)}
            />
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex align-center">
          <GeneralInput
            value={userSearch}
            rightIcon={icSearch}
            onChange={(e) => setUserSearch(e)}
            formStyle={styles.searchBar}
            placeHolder="Üye işyeri, Bayi..."
          />
          <div className={styles.btn} onClick={() => setSelectedUser(userModel)}>
            Kullanıcı ekle
            <img alt="plus" src={icPlus} className="m-l-8" />
          </div>
          <img alt="excel" src={icExcel} className="c-pointer m-l-24" />
        </div>
        {
            allUserData?.content?.length > 0 ? (
              <div className="d-flex f-column m-t-24">
                <div className={styles.tableTitle}>
                  <div>Kullanıcı ad soyad</div>
                  <div>Telefon</div>
                  <div>Email</div>
                  <div>Bağlı üye iş yeri</div>
                  <div>Bağlı bayi</div>
                  <div>Yetki türü</div>
                  <div />
                </div>
                <div className={styles.tableContent}>
                  {allUserData?.content?.map((item, index) => (
                    <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                      <div>{`${item?.name} ${item?.surname}`}</div>
                      <div>{item?.gsm}</div>
                      <div>{item?.email}</div>
                      <div>{item?.merchant?.merchantNameAlias}</div>
                      <div>{item?.merchant?.merchantName}</div>
                      <div>{item?.role}</div>
                      <div className="d-flex align-center justify-center">
                        <span
                          className="c-pointer"
                          onClick={() => {
                            getStores();
                            setSelectedUser(item);
                          }}
                        >
                          <img alt="dot" src={icEdit} />
                        </span>
                        {item?.userGuid !== userGuid
                            && (
                            <span className="c-pointer m-l-8" onClick={() => setToDeleteUser(item)}>
                              <img alt="dot" src={icDelete} />
                            </span>
                            )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex m-b-100">
                  <Pagination
                    onPageChange={(e) => {
                      pageNumber.current = e;
                      getUsers();
                    }}
                    totalPage={allUserData?.totalPages}
                    currentPage={pageNumber.current}
                  />
                </div>
              </div>
            )
              : (
                <div className={styles.noResult}>
                  <img alt="no-data" src={icNoResults} />
                  <div className="m-t-24">Kayıtlı hedefiniz bulunmamaktadır.</div>
                </div>
              )
        }
      </div>
    </BaseView>
  );
}

const userModel = {
  name: '',
  surname: '',
  email: '',
  gsm: '',
  userName: '',
  password: '',
  role: null,
  merchantId: null,
  mainMerchantId: null,
  merchant: null,
};
export default SuperMerchant;
