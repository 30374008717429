import React from 'react';
import './style.css';
import { GeneralButton } from '../../views/buttons/buttons';
import LoginHeader from '../../views/loginHeader';

function ForgotPasswordSuccess() {
  return (
    <div className="mainLogin">
      <LoginHeader />
      <div
        className="loginCont"
      >
        <div className="loginMid">
          <div className="loginT1">Şifre Sıfırlama Talebiniz Alındı!</div>
          <div className="loginT2 m-t-32">Lütfen posta kutunuza gelen maili kontrol edin.</div>
          <GeneralButton
            buttonStyle="login-bttn"
            buttonTitle="Tamam"
            buttonClick={() => { window.location.href = '/login'; }}
          />
        </div>
      </div>
      <div className="loginBottom">
        <div>FinGate Kredi Paneli</div>
        <div>© 2025 | Tüm Hakları Saklıdır | FinGate</div>
      </div>
    </div>
  );
}
export default ForgotPasswordSuccess;
