import React, { useState } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import { GeneralButton } from '../../views/buttons/buttons';
import LoginHeader from '../../views/loginHeader';
import { GeneralInput } from '../../views/newInputs/inputs';
import { savePassword } from '../../../helpers/dataManager';
import { toastStore } from '../../../context/globalStores';
import { showToastError } from '../../../context/actions/toastActions';

function ResetPassword() {
  const { guid } = useParams();
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  if (!guid) {
    window.location.href = '/login';
  }
  function passwordSave() {
    if (password.length < 8) {
      toastStore.dispatch(showToastError({ text: 'Girdiğiniz en az 8 karakter içermeli!' }));
      return;
    }
    if (password !== rePassword) {
      toastStore.dispatch(showToastError({ text: 'Girdiğiniz şifreler eşleşmiyor!' }));
      return;
    }
    if (!password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)) {
      toastStore.dispatch(showToastError({ text: 'Şifreniz en az 1 rakam ve en az 1 harf içermeli!' }));
    }
    savePassword({ guid, newPassword: password })
      .then((res) => { window.location.href = '/resetPasswordSuccess'; })
      .catch(() => {});
  }
  return (
    <div className="mainLogin">
      <LoginHeader />
      <div
        className="loginCont"
      >
        <div className="loginMid">
          <div className="loginT1">Şifrenizi belirleyiniz</div>
          <GeneralInput
            formStyle="m-t-32"
            value={password}
            onChange={(e) => setPassword(e)}
            inputType="password"
          />
          <GeneralInput
            formStyle="m-t-8"
            value={rePassword}
            onChange={(e) => setRePassword(e)}
            inputType="password"
          />
          <div className="loginT2 m-t-8">Şifreniz 8 haneli olmalı ve en az 1 adet rakam içermelidir.</div>
          <GeneralButton
            buttonStyle="login-bttn"
            buttonTitle="Kaydet"
            buttonClick={() => passwordSave()}
          />
        </div>
      </div>
      <div className="loginBottom">
        <div>FinGate Kredi Paneli</div>
        <div>© 2025 | Tüm Hakları Saklıdır | FinGate</div>
      </div>
    </div>
  );
}
export default ResetPassword;
