import { debugModeOn } from '../core/config';
import {
  loadingStore, toastStore,
} from '../context/globalStores';
import { FILE_LOADING_TRUE, LOADING_FALSE, LOADING_TRUE } from '../context/types';
// eslint-disable-next-line import/no-cycle
import { ServiceUrl } from './dataManager';
import { sessionStorageKeys } from './constant';
import { showToastError } from '../context/actions/toastActions';
import applicationEnv from './envManager';
import { clear } from './sessionManager';

const GetDataSync = async ({
  endpoint,
  body,
  header,
  method = 'POST',
  loading,
  fileLoading,
  useToken = false,
  sendSession = false,
  sendMerchant = false,
  showErrorMessage = true,
  downloadFile = false,
}) => {
  if (loading === true) {
    loadingStore.dispatch({ type: LOADING_TRUE });
  }
  if (fileLoading === true) {
    loadingStore.dispatch({ type: FILE_LOADING_TRUE });
  }
  const requestBody = {
    ...body,
  };
  const env = await applicationEnv();
  const { baseUrl } = env;
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken);
  const sessionId = sessionStorage.getItem(sessionStorageKeys.sessionId);
  const merchantId = sessionStorage.getItem(sessionStorageKeys.merchantId);
  const options = {
    method,
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8',
      ...header,
      ...useToken ? {
        Authorization: `Bearer ${accessToken}`,
      } : {},
      ...sendSession ? {
        'Session-Id': sessionId,

      } : {},
      ...sendMerchant ? {
        'Merchant-Id': merchantId,
      } : {},
    },
    body: body && JSON.stringify(requestBody),
  };

  return new Promise((resolve, reject) => {
    fetch(baseUrl + endpoint, options)
      .then((res) => {
        if (res.ok) {
          if (endpoint.includes('urlShortener')) {
            return res.text();
          }
          if (downloadFile) {
            return res.blob();
          }
          return res.json();
        }
        return res.json().then((j) => {
          throw JSON.stringify({ status: res.status, body: j });
        });
      })
      .then((result) => {
        if (loading || fileLoading || downloadFile) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        if (downloadFile) {
          const elm = document.createElement('a');
          elm.href = URL.createObjectURL(result);
          const urlId = endpoint.split('/').at(-1);
          elm.setAttribute('download', 'order.xlsx');
          elm.click();
          resolve();
        }
        if (debugModeOn) {
          console.group('ServiceRequest: ');
          console.log('endpoint', endpoint);
          console.log('requestHeader', header);
          console.log('requestBody', requestBody);
          console.log('response', result);
          console.groupEnd();
        }
        resolve(result);
      })
      .catch(async (error) => {
        if (debugModeOn) {
          console.group('ServiceRequest: ');
          console.log('endpoint', endpoint);
          console.log('requestHeader', header);
          console.log('requestBody', requestBody);
          console.log('error', JSON.parse(error));
          console.groupEnd();
        }
        if (loading || fileLoading) {
          loadingStore.dispatch({ type: LOADING_FALSE });
        }
        if (endpoint === ServiceUrl.login && JSON.parse(error).status === 401) {
          toastStore.dispatch(showToastError({ text: 'Kullanıcı adı veya şifrenizi kontrol ediniz.' }));
          return;
        }
        if (JSON.parse(error).status === 401) {
          clear();
        }
        if (JSON.parse(error).status === 420) {
          try {
            const currentRequest = {
              endpoint,
              body,
              header,
              method,
              loading,
              useToken,
              sendSession,
              sendMerchant,
              showErrorMessage,
            };
            const refreshResponse = await refreshToken(currentRequest);
            resolve(refreshResponse);
          } catch (refreshError) {
            reject(refreshError);
          }
        } else if (showErrorMessage) {
          toastStore.dispatch(showToastError({ text: 'Şu Anda İşleminizi Gerçekleştiremiyoruz. Lütfen Daha Sonra Tekrar Deneyiniz.' }));
        }

        reject(JSON.parse(error).body);
      });
  });
};

async function refreshToken(currentRequest) {
  const env = await applicationEnv();
  const { baseUrl } = env;
  const endpoint = ServiceUrl.refreshToken;
  const requestBody = {
    refreshToken: sessionStorage.getItem(sessionStorageKeys.refreshToken),
  };
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(requestBody),
  };
  return new Promise((resolve, reject) => {
    fetch(baseUrl + endpoint, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then((result) => {
        sessionStorage.setItem(sessionStorageKeys.accessToken, result.accessToken);
        GetDataSync(currentRequest)
          .then((serviceResult) => {
            resolve(serviceResult);
          })
          .catch((e) => reject(e));
      })
      .catch(() => {
        clear();
        reject();
      });
  });
}
export default GetDataSync;
