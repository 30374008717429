import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BaseView from '../../views/baseView/baseView';
import styles from './order.module.css';
import icDelete from '../../../assets/images/new/delete.svg';
import { GeneralDropdown, GeneralInput } from '../../views/newInputs/inputs';
import { GeneralButton } from '../../views/buttons/buttons';
import { MainContext } from '../../../context/store';
import { sessionStorageKeys } from '../../../helpers/constant';
import {
  jsonData, otpValidation, productCategory, sendOtp,
} from '../../../helpers/dataManager';
import { ERROR_TOAST_ON } from '../../../context/types';
import {
  addMinutes, clearPhoneFormat, convertLocalToUTCDateTime, onlyNumberFormat, phoneFormat, priceFormat, tcknCheck,
} from '../../../utils/formatter';
import applicationEnv from '../../../helpers/envManager';
import icCancel from '../../../assets/images/new/cancelBlue.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import TimeCounter from '../../views/timeCounter/timeCounter';

function Order() {
  const callRef = useRef(false);
  const { state, dispatch } = useContext(MainContext);
  const [products, setProducts] = useState([]);
  const [productType, setProductType] = useState(null);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productPricePenny, setProductPricePenny] = useState('');
  const [orderTypes, setOrderTypes] = useState([]);

  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [tckn, setTckn] = useState('');
  const [address, setAddress] = useState('');

  const [orderNumber, setOrderNumber] = useState('');
  const [otpTime, setOtpTime] = useState(null);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const merchantId = sessionStorage.getItem(sessionStorageKeys.merchantId);

  const [removeItem, setRemoveItem] = useState(null);

  function removeProduct(index) {
    const arr = [...products];
    arr.splice(index, 1);
    setProducts(arr);
  }
  useEffect(() => {
    if (!callRef.current) {
      getCategories();
    }
    callRef.current = true;
    checkMerchantOrderId();
  }, []);

  function checkMerchantOrderId() {
    const d = new Date();
    const randomOrderId = `O-${merchantId}-${d.getTime()}`;
    setOrderNumber(randomOrderId);
  }
  function getCategories() {
    productCategory()
      .then((res) => {
        const arr = res.map((e) => ({ ...e, key: e.basketType, value: e.description }));
        setOrderTypes(arr);
      })
      .catch(() => {});
  }
  function addProduct() {
    const p = {
      type: productType,
      name: productName,
      price: Number(productPrice + (productPricePenny === '' ? '.00' : `.${productPricePenny}`)),
    };
    const arr = [...products];
    arr.push(p);
    setProducts(arr);
    setProductPrice('');
    setProductPricePenny('');
    setProductName('');
    setProductType(null);
  }

  function getTotalAmount() {
    let t = 0;
    for (const p in products) {
      t += Number(products[p].price);
    }
    return t.toFixed(2);
  }
  function otpSend() {
    if (orderNumber === '') {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'Sipariş numaranızı kontrol edin' });
    } else if (products.length === 0) {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'En az 1 adet ürün girmelisiniz' });
    } else if (clearPhoneFormat(phone).length < 10) {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'Telefon numarasını kontrol edin' });
    } else if (email === '') {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'E-Posta adresini kontrol edin' });
    } else if (!tcknCheck(tckn)) {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'T.C. kimlik numarasını kontrol edin' });
    } else if (address === '') {
      dispatch.toastDispatch({ type: ERROR_TOAST_ON, text: 'Adres bilgisini kontrol edin' });
    } else {
      sendOtp({
        merchantId,
        orderId: orderNumber,
        gsm: clearPhoneFormat(phone),
      })
        .then((res) => {
          setOtpTime(120);
          setOtpModal(true);
          setOtp('');
        })
        .catch((err) => {
          if (err?.message === 'otpTimeout') {
            otpSend();
          }
        });
    }
  }

  function otpSubmit() {
    otpValidation({
      merchantId,
      orderId: orderNumber,
      gsm: clearPhoneFormat(phone),
      otpCode: otp,
    })
      .then((res) => {
        setOtpModal(false);
        setOtp('');
        if (res?.validOtp) {
          loanApp().then(() => {}).catch(() => {});
        }
      })
      .catch(() => {});
  }

  async function loanApp() {
    const env = await applicationEnv();
    const { createOrderUrl } = env;
    const expireDate = addMinutes(new Date(), -160);
    const body = {
      totalAmount: getTotalAmount(),
      expireDate: convertLocalToUTCDateTime(expireDate),
      shippingPrice: 0,
      isOffline: true,
      orderNumber,
      successRedirectUrl: `${window.location.origin}/resultSuccess?code=${merchantId}-${orderNumber}`,
      campaignNumber: '',
      failureRedirectUrl: `${window.location.origin}/resultFail?code=${merchantId}-${orderNumber}`,
      cart: products.map((e) => ({
        itemIdGivenByMerchant: '1',
        itemName: e.name,
        itemBrandName: e.name,
        unitPrice: e.price,
        quantity: '1',
        basketType: e.type.key,
      })),
      email,
      fullName,
      phoneNumber: clearPhoneFormat(phone),
      nationalIdentityNumber: tckn,
      address,
    };
    jsonData(body)
      .then((res) => {
        navigate('/result', {
          state: {
            jsonKey: res.jsonKey,
            gsm: clearPhoneFormat(phone),
            url: `${createOrderUrl}/hostedpayment?code=${res.jsonKey}`,
            orderNumber,
            body,
          },
        });
      })
      .catch(() => {
      });
  }
  function askForRemove(index, name) {
    setRemoveItem({ index, name });
  }
  return (
    <BaseView>
      <GeneralModal
        toggle={() => {
          setOtpModal(false);
          setOtp('');
        }}
        visible={otpModal}
      >
        <div className="d-flex f-column p-30">
          <div className="d-flex align-center m-b-20">
            <div className="title1 f1">OTP Doğrulama</div>
            <div
              onClick={() => {
                setOtpModal(false);
                setOtp('');
              }}
              className="p-8 c-pointer"
            >
              <img alt="cancel" src={icCancel} />
            </div>
          </div>
          {otpTime > 0 ? (
            <div>
              <div className="d-flex">
                <TimeCounter timeUp={() => setOtpTime(0)} seconds={120} />
                <GeneralInput
                  placeHolder="Doğrulama Kodu"
                  maxLength={4}
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  formStyle="f1 m-l-20"
                />
              </div>
              <GeneralButton
                buttonClick={() => otpSubmit()}
                isGhost={otp.length < 4}
                buttonTitle="Doğrula"
                buttonStyle="w-300 m-t-32"
              />
            </div>
          ) : (
            <GeneralButton buttonTitle="Tekrar Gönder" buttonType="tertiary" buttonStyle="w-300" buttonClick={() => otpSend()} />
          )}
        </div>
      </GeneralModal>
      <GeneralModal
        toggle={() => {
          setRemoveItem(null);
        }}
        visible={removeItem}
      >
        <div className="d-flex f-column p-32 w-300">
          <div className="d-flex align-center">
            <div className="modal-content-title">Ürün Silme</div>
            <div
              onClick={() => {
                setRemoveItem(null);
              }}
              className="c-pointer"
            >
              <img alt="cancel" src={icCancel} />
            </div>
          </div>
          <div className="m-t-16 modal-content-text">{removeItem?.name}</div>
          <GeneralButton
            buttonStyle="m-t-16"
            buttonTitle="ÜRÜNÜ SİL"
            buttonClick={() => {
              removeProduct(removeItem.index);
              setRemoveItem(null);
            }}
          />
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className={styles.cont1}>
          <GeneralInput
            formStyle="m-b-16 w-300"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e)}
            title="Sipariş Numarası"
            placeHolder="Sipariş Numarası"
          />
        </div>
        <div className={`${styles.t1} m-t-8`}>Müşteri bilgileri</div>
        <div className={`${styles.cont1} m-t-8`}>
          <GeneralInput
            maxLength={11}
            formStyle={`${styles.resetMargin} f1`}
            value={tckn}
            onChange={(e) => setTckn(e)}
            title="T.C. kimlik no"
            placeHolder="T.C. kimlik no"
          />
          <GeneralInput
            formStyle={`${styles.resetMargin} f1 m-l-8 m-r-8`}
            maxLength={15}
            value={phoneFormat(phone)}
            onChange={(e) => setPhone(e)}
            title="Cep telefonu"
            placeHolder="Cep telefonu"
          />
          <GeneralInput
            formStyle={`${styles.resetMargin} f1 m-l-8 m-r-8`}
            value={fullName}
            onChange={(e) => setFullName(e)}
            title="Ad soyad"
            placeHolder="Ad soyad"
          />
          <GeneralInput
            formStyle={`${styles.resetMargin} f1`}
            value={email}
            onChange={(e) => setEmail(e)}
            title="E-mail"
            placeHolder="E-mail"
          />
        </div>
        <div className={`${styles.cont1} m-t-8`}>
          <GeneralInput
            formStyle={`${styles.resetMargin} f1`}
            value={address}
            onChange={(e) => setAddress(e)}
            title="Adres"
            placeHolder="Adres"
            textArea
          />
        </div>
        <div className={`${styles.t1} m-t-24`}>Ürün detayları</div>
        <div className={`${styles.cont1} m-t-8`}>
          <GeneralDropdown
            formStyle={`${styles.resetMargin} f1 z1`}
            dataList={orderTypes}
            selected={productType}
            setSelected={(e) => setProductType(e)}
            title="Ürün grubu"
            placeHolder="Ürün grubu"
          />
          <GeneralInput
            formStyle={`${styles.resetMargin} f1 m-l-8 m-r-8`}
            value={productName}
            onChange={(e) => setProductName(e)}
            title="Ürün adı"
            placeHolder="Ürün adı"
          />
          <GeneralInput
            formStyle={`${styles.resetMargin} ${styles.smallBtn} f1`}
            value={productPrice}
            price
            pennyValue={productPricePenny}
            pennyOnChange={(e) => setProductPricePenny(onlyNumberFormat(e))}
            onChange={(e) => setProductPrice(onlyNumberFormat(e))}
            title="Ürün tutarı (TL)"
            placeHolder="00"
          />
        </div>
        <div className={`${styles.cont1} m-t-8`}>
          <div className={`${styles.resetMargin} f1`} />
          <div className={`${styles.resetMargin} f1 m-r-32`} />
          <GeneralButton
            isGhost={productType === null || productName.length < 4 || productPrice === ''}
            buttonStyle={`${styles.resetMargin} ${styles.smallBtn} f1`}
            buttonTitle="Ekle"
            buttonClick={() => addProduct()}
          />
        </div>
        {products.length > 0 && (
        <div className={styles.tableLayer}>
          <div className={styles.tableLayer1}>
            <div className={styles.tableTitle}>
              <div className="w-200">Ürün grubu</div>
              <div className="f1">Ürün adı</div>
              <div className="w-200">Ürün tutarı (TL)</div>
              <div />
            </div>
            <div className={styles.tableContent}>
              {products.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}
                >
                  <div className="w-200">{item.type.value}</div>
                  <div className="f1">{item.name}</div>
                  <div className="w-200">
                    {priceFormat(item.price)}
                    {' '}
                    TL
                  </div>
                  <div>
                    <div className="d-flex align-center">
                      <div
                        className="c-pointer"
                        onClick={() => {
                          askForRemove(index, item.name);
                        }}
                      >
                        <img alt="dot" src={icDelete} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        )}
        <div className="d-flex justify-center">
          <GeneralButton buttonStyle={styles.createOrderBtn} buttonTitle="Sipariş oluştur" buttonClick={() => otpSend()} />
        </div>
      </div>
    </BaseView>
  );
}

export default Order;
