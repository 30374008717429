// eslint-disable-next-line import/no-cycle
import GetDataSync from './connectionManager';
import applicationEnv from './envManager';
import { toastStore } from '../context/globalStores';
import { showToastError } from '../context/actions/toastActions';
import { sessionStorageKeys } from './constant';

const env = await applicationEnv();
const { apiVersion } = env;
const ServiceUrl = {
  jsonData: `/offline/${apiVersion}/jsonData`,
  jsonDataExpire: `/OnlineShoppingLoan/${apiVersion}/jsonData`,
  login: `/offline/${apiVersion}/login`,
  refreshToken: `/offline/${apiVersion}/refresh-token`,
  productCategory: `/offline/${apiVersion}/productCategory`,
  parameter: `/offline/${apiVersion}/parameter/`,
  merchants: `/offline/${apiVersion}/merchants?isStore=`,
  merchantWithRelation: `/offline/${apiVersion}/merchants-with-relations?sort=merchantName,ASC`,
  merchantCount: `/offline/${apiVersion}/merchant/count`,
  bankCount: `/offline/${apiVersion}/bank/count`,
  creditSummary: `/offline/${apiVersion}/credit-summary`,
  merchantCreditSummary: `/offline/${apiVersion}/merchant-credit-summary?guid=`,
  banks: `/offline/${apiVersion}/banks`,
  merchantBanks: `/offline/${apiVersion}/merchant-banks`,
  createMerchantBanks: `/offline/${apiVersion}/create-merchant-banks`,
  getBanksWithContact: `/offline/${apiVersion}/banks-with-contacts`,
  partners: `/offline/${apiVersion}/partners`,
  searchAllMerchants: `/offline/${apiVersion}/order?userGuid=`,
  getMerchant: `/offline/${apiVersion}/merchant?userGuid=`,
  orderCounts: `/offline/${apiVersion}/order-counts?userGuid=`,
  storeCount: `/offline/${apiVersion}/store/count?userGuid=`,
  getSuperMerchants: `/offline/${apiVersion}/merchant/branch?userGuid=`,
  orderCountsByMonth: `/offline/${apiVersion}/order-counts-by-month?userGuid=`,
  monthlyTargets: `/offline/${apiVersion}/monthly-targets`,
  refunds: `/OnlineShoppingLoan/${apiVersion}/refunds`,
  sms: `/sms/${apiVersion}/sendSms`,
  sendOtp: `/sms/${apiVersion}/sendOtp`,
  otpValidation: `/sms/${apiVersion}/otpValidation`,
  order: `/offline/${apiVersion}/order`,
  merchantCreate: `/offline/${apiVersion}/merchants`,
  storeCreate: `/offline/${apiVersion}/store`,
  merchantUpdate: `/offline/${apiVersion}/merchant`,
  users: `/offline/${apiVersion}/users?guid=`,
  createUser: `/offline/${apiVersion}/create-user?guid=`,
  export: `/offline/${apiVersion}/export?userGuid=`,
  updateUser: `/offline/${apiVersion}/users`,
  store: `/offline/${apiVersion}/merchant/stores?mainMerchantId=`,
  forgotPassword: `/offline/${apiVersion}/forgot-password`,
  savePassword: `/offline/${apiVersion}/resetPassword`,
};

const jsonData = ({
  totalAmount,
  shippingPrice,
  expireDate,
  currency = '949',
  orderNumber,
  successRedirectUrl,
  failureRedirectUrl,
  campaignNumber,
  cart,
  email,
  phoneNumber,
  nationalIdentityNumber,
  address,
  fullName,
  isOffline,
}) => {
  const merchantId = sessionStorage.getItem(sessionStorageKeys.merchantId);
  const endpoint = ServiceUrl.jsonData;
  const body = {
    merchantId,
    totalAmount,
    shippingPrice,
    expireDate,
    currency,
    orderNumber,
    successRedirectUrl,
    failureRedirectUrl,
    campaignNumber,
    cart,
    isOffline,
    customerInfo: {
      email,
      phoneNumber,
      nationalIdentityNumber,
      address,
      fullName,
    },
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      sendMerchant: true,
      sendSession: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getJsonData = async ({
  jsonKey,
}) => {
  const endpoint = `${ServiceUrl.jsonDataExpire}/${jsonKey}`;
  const env = await applicationEnv();
  const { token } = env;
  const header = { apikey: `${token}` };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      header,
      loading: true,
      sendMerchant: false,
      sendSession: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      method: 'GET',
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const login = ({
  username, password,
}) => {
  const endpoint = ServiceUrl.login;
  const body = {
    username,
    password,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        toastStore.dispatch(showToastError({ text: err?.messages[0] }));
        reject(err);
      });
  });
};

const productCategory = () => {
  const endpoint = ServiceUrl.productCategory;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const refunds = async ({
  bankCode,
  amount,
  orderNumber,
}) => {
  const endpoint = ServiceUrl.refunds;
  const body = {
    bankCode,
    amount,
    orderNumber,
  };
  const env = await applicationEnv();
  const { token } = env;
  const header = { apikey: `${token}` };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      header,
      sendMerchant: true,
      sendSession: true,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const sms = ({
  gsm,
  redirectionUrl,
}) => {
  const endpoint = ServiceUrl.sms;
  const body = {
    gsm,
    redirectionUrl,
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const e = new Error();
      e.message = 'otpTimeout';
      reject(e);
    }, 5000);
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sendOtp = ({
  merchantId,
  orderId,
  gsm,
}) => {
  const endpoint = ServiceUrl.sendOtp;
  const body = {
    merchantId,
    orderId,
    gsm,
  };
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const e = new Error();
      e.message = 'otpTimeout';
      reject(e);
    }, 5000);
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const otpValidation = ({
  merchantId,
  orderId,
  userId,
  gsm,
  otpCode,
}) => {
  const endpoint = ServiceUrl.otpValidation;
  const body = {
    merchantId,
    orderId,
    userId,
    gsm,
    otpCode,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      loading: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err?.resultMessage.toLowerCase().includes('otp')) {
          toastStore.dispatch(showToastError({ text: err.resultMessage }));
        }
        reject(err);
      });
  });
};

const merchants = (isStore = false) => {
  const endpoint = ServiceUrl.merchants + String(isStore);
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getParameter = ({ parameter }) => {
  const endpoint = ServiceUrl.parameter + parameter;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const merchantWithRelation = ({
  page, size, isStore, query,
}) => {
  const endpoint = `${ServiceUrl.merchantWithRelation}&page=${page}&size=${size}&isStore=${isStore}`;
  const body = {
    query,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getAllUsers = ({
  page, size, query,
}) => {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.users}${userGuid}&page=${page}&size=${size}&sort=updatedDate,DESC`;
  const body = {
    query,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const searchAllMerchants = ({
  startDate,
  endDate,
  orderId,
  applicationStatusCode,
  merchants,
}) => {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.searchAllMerchants + userGuid;
  const body = {
    startDate,
    endDate,
    orderId,
    applicationStatusCode,
    merchants,
  };
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      body,
      fileLoading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
function getMerchant({ userGuid }) {
  const endpoint = ServiceUrl.getMerchant + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function orderCounts(startDate, endDate, bankId) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.orderCounts + userGuid}${startDate ? `&startDate=${startDate.split('T')[0]}` : ''}${endDate ? `&endDate=${endDate.split('T')[0]}` : ''}${bankId ? `&bankId=${bankId}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function storeCount() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.storeCount + userGuid}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getSuperMerchants() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.getSuperMerchants + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function orderCountsByMonth() {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.orderCountsByMonth + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function monthlyTargets(time) {
  const f = time.split('')[0];
  const endpoint = `${ServiceUrl.monthlyTargets}?${time.length > 0 ? (`${f}=${time.split(f)[1]}`) : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function saveMonthlyTarget({
  monthYear, creditIncomeGoal, merchantAcquisitionGoal, creditTurnoverGoal,
}) {
  const body = {
    monthYear, creditIncomeGoal, merchantAcquisitionGoal, creditTurnoverGoal,
  };
  const endpoint = `${ServiceUrl.monthlyTargets}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function merchantCount() {
  const endpoint = ServiceUrl.merchantCount;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function bankCount() {
  const endpoint = ServiceUrl.bankCount;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getBanks() {
  const endpoint = ServiceUrl.banks;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createBank({ data }) {
  const endpoint = ServiceUrl.banks;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function merchantBanks({ page, size, query }) {
  const endpoint = `${ServiceUrl.merchantBanks}?page=${page}&size=${size}&sort=merchant.merchantName,DESC`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: { query },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateMerchantBanks({ body, id }) {
  const endpoint = `${ServiceUrl.merchantBanks}/${id}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'PUT',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createMerchantBanks({ body }) {
  const endpoint = `${ServiceUrl.createMerchantBanks}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteMerchantBanks({ id }) {
  const endpoint = `${ServiceUrl.merchantBanks}/${id}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'DELETE',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateBank({ data, id }) {
  const endpoint = `${ServiceUrl.banks}/${id}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'PUT',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getBanksWithContact() {
  const endpoint = ServiceUrl.getBanksWithContact;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function partnerList() {
  const endpoint = ServiceUrl.partners;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function creditSummary({ bankId, merchantId }) {
  const endpoint = `${ServiceUrl.creditSummary}${bankId ? `?bankId=${bankId}` : '?'}${merchantId ? `merchantId=${merchantId}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function merchantCreditSummary({ storeId }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.merchantCreditSummary}${userGuid}${storeId ? `&storeId=${storeId}` : ''}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createPartner({ data }) {
  const endpoint = ServiceUrl.partners;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updatePartner({ data, id }) {
  const endpoint = `${ServiceUrl.partners}/${id}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'PUT',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getOrderList({
  orderId,
  applicationStatusCodes,
  startDate,
  endDate,
  merchants,
  banks,
  integrationTypeId,
  creditType,
  page,
  size,
}) {
  const body = {
    orderId,
    applicationStatusCodes,
    startDate,
    endDate,
    merchants,
    banks,
    integrationTypeId,
    creditType,
  };
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.order}?userGuid=${userGuid}&page=${page}&size=${size}&sort=updatedDate,DESC`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createMerchant({ body }) {
  const endpoint = ServiceUrl.merchantCreate;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createStore({ body }) {
  const endpoint = ServiceUrl.storeCreate;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createUser({ body }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.createUser + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: false,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        toastStore.dispatch(showToastError({ text: err?.messages[0] }));
        reject(err);
      });
  });
}
function excel({ body }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = ServiceUrl.export + userGuid;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      downloadFile: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateUser({ updatedRole, userId }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.updateUser}/${userId}?guid=${userGuid}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'PUT',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body: { updatedRole },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteUser({ userId }) {
  const userGuid = sessionStorage.getItem(sessionStorageKeys.userGuid);
  const endpoint = `${ServiceUrl.updateUser}/${userId}?guid=${userGuid}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'DELETE',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function stores({ mainMerchantId }) {
  const endpoint = ServiceUrl.store + mainMerchantId;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'GET',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateMerchant({ body, id }) {
  const endpoint = `${ServiceUrl.merchantUpdate}/${id}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'PUT',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      useToken: true,
      body,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function forgotPassword({ email }) {
  const endpoint = ServiceUrl.forgotPassword;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      body: { email },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function savePassword({ guid, newPassword }) {
  const endpoint = `${ServiceUrl.savePassword}/${guid}`;
  return new Promise((resolve, reject) => {
    GetDataSync({
      endpoint,
      method: 'POST',
      loading: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      body: { newPassword },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  login,
  savePassword,
  forgotPassword,
  productCategory,
  refunds,
  sendOtp,
  sms,
  otpValidation,
  merchants,
  searchAllMerchants,
  getMerchant,
  jsonData,
  getSuperMerchants,
  ServiceUrl,
  merchantCount,
  bankCount,
  creditSummary,
  createPartner,
  updatePartner,
  getBanks,
  createBank,
  updateBank,
  orderCounts,
  getOrderList,
  merchantWithRelation,
  getParameter,
  merchantCreditSummary,
  orderCountsByMonth,
  storeCount,
  getBanksWithContact,
  partnerList,
  createMerchant,
  createStore,
  updateMerchant,
  monthlyTargets,
  saveMonthlyTarget,
  getAllUsers,
  stores,
  createUser,
  updateUser,
  deleteUser,
  excel,
  getJsonData,
  merchantBanks,
  deleteMerchantBanks,
  updateMerchantBanks,
  createMerchantBanks,
};
