import React, { useEffect, useState } from 'react';
import styles from './navigator.module.css';
import ic1 from '../../../assets/images/navigator/homepage-1.svg';
import ic2 from '../../../assets/images/navigator/homepage.svg';
import ic3 from '../../../assets/images/navigator/merchant.svg';
import ic4 from '../../../assets/images/navigator/merchant-1.svg';
import ic5 from '../../../assets/images/navigator/yetki-1.svg';
import ic6 from '../../../assets/images/navigator/yetki.svg';
import ic7 from '../../../assets/images/navigator/hedefler-1.svg';
import ic8 from '../../../assets/images/navigator/hedefler.svg';
import ic9 from '../../../assets/images/navigator/raporlar.svg';
import ic10 from '../../../assets/images/navigator/raporlar-1.svg';
import ic11 from '../../../assets/images/navigator/finansal_kurum-1.svg';
import ic12 from '../../../assets/images/navigator/finansal kurum.svg';
import ic13 from '../../../assets/images/navigator/siparis-1.svg';
import ic14 from '../../../assets/images/navigator/siparis.svg';
import gear1 from '../../../assets/images/navigator/gear1.svg';
import gear2 from '../../../assets/images/navigator/gear2.svg';
import statistics1 from '../../../assets/images/navigator/statistics1.png';
import statistics2 from '../../../assets/images/navigator/statistics2.png';
import icDr from '../../../assets/images/new/dropdown-black.svg';
import icPw from '../../../assets/images/poweredLogo.svg';
import icLogOut from '../../../assets/images/new/log_out_2.svg';
import { sessionStorageKeys, userRoles } from '../../../helpers/constant';
import { clear } from '../../../helpers/sessionManager';

function checkSelection(path) {
  return window.location.pathname.includes(path);
}

function Navigator() {
  const [reportsOpen, setReportsOpen] = useState(false);
  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);
  useEffect(() => {
    if (checkSelection('/reports')) {
      setReportsOpen(true);
    }
  }, []);
  function signOut() {
    clear();
  }
  return (
    <div className={styles.main}>
      <div className="d-flex f-column f1">
        <div
          className={`${styles.card1} ${checkSelection('/dashboard') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/dashboard';
          }}
        >
          <img alt="menu" src={checkSelection('/dashboard') ? ic1 : ic2} className="m-r-16" />
          <div>Anasayfa</div>
        </div>
        <div
          className={`${styles.card} ${checkSelection('/paymentStatistics') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/paymentStatistics';
          }}
        >
          <img alt="menu" src={checkSelection('/paymentStatistics') ? statistics1 : statistics2} className="m-r-16" />
          <div>Kredi İstatistikleri</div>
        </div>
        <div
          className={`${styles.card1} ${checkSelection('/reports') ? styles.selected : ''}`}
          onClick={() => {
            setReportsOpen(!reportsOpen);
          }}
        >
          <img alt="menu" src={checkSelection('/reports') ? ic10 : ic9} className="m-r-16" />
          <div className="f1">Raporlar</div>
          <img alt="reports" src={icDr} />
        </div>
        {reportsOpen && (
          <>
            <div
              className={`${styles.card1} ${checkSelection('/reports/all') ? styles.selected : ''}`}
              onClick={() => {
                window.location.href = '/reports/all';
              }}
            >
              <div className="f1 m-l-40">Tümü</div>
            </div>
            <div
              className={`${styles.card1} ${checkSelection('/reports/used') ? styles.selected : ''}`}
              onClick={() => {
                window.location.href = '/reports/used';
              }}
            >
              <div className="f1 m-l-40">Kullandırılan</div>
            </div>
            <div
              className={`${styles.card1} ${checkSelection('/reports/canceled') ? styles.selected : ''}`}
              onClick={() => {
                window.location.href = '/reports/canceled';
              }}
            >
              <div className="f1 m-l-40">Red</div>
            </div>
            <div
              className={`${styles.card1} ${checkSelection('/reports/refund') ? styles.selected : ''}`}
              onClick={() => {
                window.location.href = '/reports/refund';
              }}
            >
              <div className="f1 m-l-40">İade</div>
            </div>
            <div
              className={`${styles.card1} ${checkSelection('/reports/redirected') ? styles.selected : ''}`}
              onClick={() => {
                window.location.href = '/reports/redirected';
              }}
            >
              <div className="f1 m-l-40">Bankaya Yönlendirildi</div>
            </div>
          </>
        )}
        {(userRole === userRoles.merchantAdmin || userRole === userRoles.merchantUser) && (
        <div
          className={`${styles.card1} ${checkSelection('/order') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/order';
          }}
        >
          <img alt="menu" src={checkSelection('/order') ? ic13 : ic14} className="m-r-16" />
          <div>Yeni Sipariş</div>
        </div>
        )}
        {userRole === userRoles.superUser && (
        <div
          className={`${styles.card} ${checkSelection('/merchants') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/merchants';
          }}
        >
          <img alt="menu" src={checkSelection('/merchants') ? ic4 : ic3} className="m-r-16" />
          <div>Üye İş Yeri</div>
        </div>
        )}
        {userRole === userRoles.superUser && (
        <div
          className={`${styles.card} ${checkSelection('/financials') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/financials';
          }}
        >
          <img alt="menu" src={checkSelection('/financials') ? ic11 : ic12} className="m-r-16" />
          <div>Finansal Kurumlar</div>
        </div>
        )}
        {userRole === userRoles.superUser && (
        <div
          className={`${styles.card} ${checkSelection('/merchantBanks') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/merchantBanks';
          }}
        >
          <img alt="menu" src={checkSelection('/merchantBanks') ? gear1 : gear2} className="m-r-16" />
          <div>Entegrasyon Yönetimi</div>
        </div>
        )}
        {userRole === userRoles.superUser && (
        <div
          className={`${styles.card} ${checkSelection('/goals') ? styles.selected : ''}`}
          onClick={() => {
            window.location.href = '/goals';
          }}
        >
          <img alt="menu" src={checkSelection('/goals') ? ic7 : ic8} className="m-r-16" />
          <div>Hedefler</div>
        </div>
        )}
        {(userRole === userRoles.superUser || userRole === userRoles.superMerchant || userRole === userRoles.merchantAdmin)
                && (
                <div
                  className={`${styles.card} ${checkSelection('/users') ? styles.selected : ''}`}
                  onClick={() => {
                    window.location.href = '/users';
                  }}
                >
                  <img alt="menu" src={checkSelection('/users') ? ic5 : ic6} className="m-r-16" />
                  <div>Kullanıcılar</div>
                </div>
                )}
      </div>
      <div
        className={`${styles.card} d-flex-small`}
        onClick={() => {
          signOut();
        }}
      >
        <img alt="menu" src={icLogOut} className="m-r-16" />
        <div>Çıkış Yap</div>
      </div>
      <img alt="fingate" src={icPw} />
    </div>
  );
}

export default Navigator;
