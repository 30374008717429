import React, { useEffect, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import { GeneralDatePicker, GeneralDropdown } from '../../views/newInputs/inputs';
import { getBanks, orderCounts } from '../../../helpers/dataManager';
import { priceFormat } from '../../../utils/formatter';
import { GeneralButton } from '../../views/buttons/buttons';

function SuperUser({ styles }) {
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState({
    11: null,
    7: null,
    5: null,
    12: null,
    6: null,
  });
  const [totalData, setTotalData] = useState({
    averageAmount: 0,
    codeCount: 0,
    statusCode: 0,
    totalAmount: 0,
    totalAmountRate: '%0',
    totalCountRate: '%0',
  });
  useEffect(() => {
    getParameters();
  }, []);
  useEffect(() => {
    getOrderCounts();
  }, [startDate, endDate, selectedBank]);
  function getParameters() {
    getBanks()
      .then((res) => { setBankList(res.map((e) => ({ ...e, key: e.id, value: e.bankName }))); })
      .catch(() => {});
  }
  function getOrderCounts() {
    let ed = null;
    if (endDate) {
      ed = new Date(endDate);
      ed = new Date(ed.setDate(ed.getDate() + 1));
    }
    let sd = null;
    if (startDate) {
      sd = new Date(startDate);
      sd = new Date(sd.setDate(sd.getDate() + 1));
    }
    orderCounts(
      (startDate && endDate) ? sd?.toISOString() : null,
      (startDate && endDate) ? ed?.toISOString() : null,
      selectedBank?.key ?? null,
    )
      .then((res) => {
        if (res?.counts) {
          prepareData(res.counts);
        }
      })
      .catch(() => {});
  }
  function getTotalRow(d) {
    const t = {
      codeCount: 0,
      statusCode: 0,
      totalAmount: 0,
      totalAmountRate: '%0',
      totalCountRate: '%0',
    };
    t.codeCount = (d[11] ? d[11].codeCount : 0) + (d[7] ? d[7].codeCount : 0) + (d[5] ? d[5].codeCount : 0) + (d[12] ? d[12].codeCount : 0) + (d[6] ? d[6].codeCount : 0);
    t.totalAmount = (d[11] ? d[11].totalAmount : 0) + (d[7] ? d[7].totalAmount : 0) + (d[5] ? d[5].totalAmount : 0) + (d[12] ? d[12].totalAmount : 0) + (d[6] ? d[6].totalAmount : 0);
    t.totalAmountRate = '%100';
    t.totalCountRate = '%100';
    setTotalData(t);
  }
  function prepareData(data) {
    const allData = {
      11: null,
      7: null,
      5: null,
      12: null,
      6: null,
    };
    data.forEach((item) => {
      if (item.statusCode === 11) {
        allData[11] = item;
      }
      if (item.statusCode === 7) {
        allData[7] = item;
      }
      if (item.statusCode === 5) {
        allData[5] = item;
      }
      if (item.statusCode === 12) {
        allData[12] = item;
      }
      if (item.statusCode === 6) {
        allData[6] = item;
      }
    });
    setData(allData);
    getTotalRow(allData);
  }
  return (
    <BaseView>
      <div className={styles.main}>
        <div className="d-flex align-center justify-space-between">
          <div className="d-flex align-center">
            <GeneralDatePicker
              value={startDate}
              onChange={(e) => setStartDate(e)}
              placeHolder="Başlangıç Tarihi"
              formStyle="w-260"
            />
            <GeneralDatePicker
              value={endDate}
              onChange={(e) => setEndDate(e)}
              placeHolder="Bitiş Tarihi"
              formStyle="w-260 m-l-16 m-r-16"
            />
            <GeneralDropdown
              dataList={bankList}
              selected={selectedBank}
              formStyle="w-260"
              placeHolder="Finansal kurum"
              setSelected={(e) => setSelectedBank(e)}
            />
          </div>
          <GeneralButton
            buttonStyle="m-t-16 w-200"
            buttonTitle="Filtreleri sıfırla"
            buttonClick={() => {
              setStartDate(null);
              setEndDate(null);
              setSelectedBank(null);
            }}
          />
        </div>
        <div className={styles.c1}>
          <div className="d-flex w100">
            <div className={styles.line}>
              <div className={styles.t1}>Kredi durumu</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t1}>Adet</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t1}>Tutar (TL)</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t1}>Sepet ortalaması</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t1}>Oran (Adet)</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t1}>Oran (Tutar)</div>
            </div>
          </div>
          <div className={styles.c2}>
            <div className={styles.line}>
              <div className={styles.t3}>Kullandırılan</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[11]?.codeCount || 0}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[11]?.totalAmount || 0)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[11]?.averageAmount || 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[11]?.totalCountRate || 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[11]?.totalAmountRate || 0}</div>
            </div>
          </div>
          <div className={styles.c3}>
            <div className={styles.line}>
              <div className={styles.t3}>Red</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[7]?.codeCount || 0}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[7]?.totalAmount || 0)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[7]?.averageAmount || 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[7]?.totalCountRate || 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[7]?.totalAmountRate || 0}</div>
            </div>
          </div>
          <div className={styles.c2}>
            <div className={styles.line}>
              <div className={styles.t3}>İade</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[5]?.codeCount || 0}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[5]?.totalAmount || 0)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[5]?.averageAmount || 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[5]?.totalCountRate || 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[5]?.totalAmountRate || 0}</div>
            </div>
          </div>
          <div className={styles.c3}>
            <div className={styles.line}>
              <div className={styles.t3}>Yönlendirildi</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[12]?.codeCount || 0}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[12]?.totalAmount || 0)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[12]?.averageAmount || 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[12]?.totalCountRate || 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[12]?.totalAmountRate || 0}</div>
            </div>
          </div>
          <div className={styles.c2}>
            <div className={styles.line}>
              <div className={styles.t3}>Parçalı iade</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[6]?.codeCount || 0}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[6]?.totalAmount || 0)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(data[6]?.averageAmount || 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[6]?.totalCountRate || 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{data[6]?.totalAmountRate || 0}</div>
            </div>
          </div>
          <div className={styles.c4}>
            <div className={styles.line}>
              <div className={styles.t3}>Toplam</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{totalData?.codeCount}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(totalData?.totalAmount)}</div>
            </div>
            <div className={`${styles.line} justify-end`}>
              <div className={styles.t2}>{priceFormat(totalData?.totalAmount ? (totalData.totalAmount / totalData.codeCount) : 0)}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{totalData?.totalAmount ? totalData?.totalCountRate : 0}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.t2}>{totalData?.totalAmount ? totalData?.totalAmountRate : 0}</div>
            </div>
          </div>
        </div>
      </div>
    </BaseView>
  );
}

export default SuperUser;
