import React from 'react';
import logo from '../../assets/images/header/fingate_logo_white_1.svg';
import './viewsStyles.css';

function LoginHeader() {
  return (
    <div className="loginHeader">
      <img alt="logo" src={logo} />
    </div>
  );
}

export default LoginHeader;
