import React, { useEffect, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import styles from './goals.module.css';
import icExcel from '../../../assets/images/new/excel.svg';
import {
  GeneralDropdown, GeneralInput,
} from '../../views/newInputs/inputs';
import icPlus from '../../../assets/images/new/plus.svg';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import icEdit from '../../../assets/images/new/edit.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralButton } from '../../views/buttons/buttons';
import { monthlyTargets, orderCounts, saveMonthlyTarget } from '../../../helpers/dataManager';
import { goalsMonths, targetMonths } from '../../../helpers/constant';
import { onlyNumberFormat, priceFormat } from '../../../utils/formatter';
import icNoResults from '../../../assets/images/new/noTarget.svg';

function Goals() {
  const thisMonth = new Date().getMonth() + 1;
  const thisYear = new Date().getFullYear();
  const [selectedRange, setSelectedRange] = useState(`Y${thisYear}`);
  const [rangeData, setRangeData] = useState(null);
  const [orderCountsData, setOrderCountsData] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);

  useEffect(() => {
    getMonthlyTarget();
    getOrderCounts();
  }, [selectedRange]);
  function handleSelectedTarget(key, value) {
    const data = { ...selectedTarget };
    data[key] = value;
    setSelectedTarget(data);
  }
  function getMonthlyTarget() {
    monthlyTargets(selectedRange)
      .then((res) => { setRangeData(res); })
      .catch(() => {});
  }
  function getOrderCounts() {
    orderCounts(getSEDate().startDate, getSEDate().endDate, null)
      .then((res) => { setOrderCountsData(res); })
      .catch(() => {});
  }
  function getSEDate() {
    const a = selectedRange.split('')[0];
    if (a) {
      const m = selectedRange.split(a)[1];
      switch (a) {
        case 'M':
          // eslint-disable-next-line no-case-declarations
          const f1 = new Date(thisYear, Number(m) - 1, 2);
          // eslint-disable-next-line no-case-declarations
          const l1 = new Date(thisYear, Number(m), 1);
          return { startDate: f1.toISOString(), endDate: l1.toISOString() };
        case 'Q':
          // eslint-disable-next-line no-case-declarations
          const f2 = new Date(thisYear, (Number(m) - 1) * 3, 2);
          // eslint-disable-next-line no-case-declarations
          const l2 = new Date(thisYear, Number(m) * 3, 1);
          return { startDate: f2.toISOString(), endDate: l2.toISOString() };
        case 'Y':
          // eslint-disable-next-line no-case-declarations
          const f3 = new Date(m, 0, 2);
          // eslint-disable-next-line no-case-declarations
          const l3 = new Date(m, 12, 1);
          return { startDate: f3.toISOString(), endDate: l3.toISOString() };
        default:
          return { startDate: '', endDate: '' };
      }
    } else {
      return { startDate: '', endDate: '' };
    }
  }
  function getCount(code) {
    if (orderCountsData) {
      const c = orderCountsData.counts.find((e) => e.statusCode === code);
      return c?.codeCount || 0;
    }
    return 0;
  }
  function saveTarget() {
    saveMonthlyTarget({ ...selectedTarget })
      .then((res) => {
        getMonthlyTarget();
        setSelectedTarget(null);
      })
      .catch(() => {});
  }

  return (
    <BaseView>
      <GeneralModal visible={selectedTarget !== null} toggle={() => setSelectedTarget(null)}>
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Hedef Detayları</div>
            <div className="c-pointer" onClick={() => setSelectedTarget(null)}>
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex f-column">
            <div className="m-t-8">
              <GeneralDropdown
                isLight
                title="Ay & Yıl*"
                placeHolder="Ay & Yıl*"
                dataList={targetMonths}
                formStyle="z1"
                selected={{
                  key: selectedTarget?.monthYear,
                  value: targetMonths.find((e) => e.key === selectedTarget?.monthYear)?.value,
                }}
                setSelected={(e) => handleSelectedTarget('monthYear', e.key)}
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedTarget?.creditIncomeGoal}
                onChange={(e) => handleSelectedTarget('creditIncomeGoal', onlyNumberFormat(e))}
                placeHolder="Kredi gelir hedefi (TL)*"
                title="Kredi gelir hedefi (TL)*"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedTarget?.merchantAcquisitionGoal}
                onChange={(e) => handleSelectedTarget('merchantAcquisitionGoal', onlyNumberFormat(e))}
                placeHolder="Üye iş yeri kazanım hedefi (Adet)* "
                title="Üye iş yeri kazanım hedefi (Adet)*"
              />
            </div>
            <div className="m-t-8">
              <GeneralInput
                darkText
                value={selectedTarget?.creditTurnoverGoal}
                onChange={(e) => handleSelectedTarget('creditTurnoverGoal', onlyNumberFormat(e))}
                placeHolder="Ciro kazanım hedefi (TL)* "
                title="Ciro kazanım hedefi (TL)*"
              />
            </div>
            <div className="d-flex m-t-16">
              <GeneralButton
                buttonTitle="Kaydet"
                buttonStyle="w100"
                isGhost={
                selectedTarget?.creditIncomeGoal === '' || selectedTarget?.merchantAcquisitionGoal === '' || selectedTarget?.monthYear === ''
              }
                buttonClick={() => saveTarget()}
              />
            </div>
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div>
          <div className="d-flex align-center justify-space-between">
            <div
              className={`${styles.c2} ${selectedRange === `M${thisMonth}` ? styles.selected : ''}`}
              onClick={() => {
                const thisMonth = new Date().getMonth() + 1;
                setSelectedRange(`M${thisMonth}`);
              }}
            >
              Bu Ay
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 'Q1' ? styles.selected : ''}`}
              onClick={() => setSelectedRange('Q1')}
            >
              Q1
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 'Q2' ? styles.selected : ''}`}
              onClick={() => setSelectedRange('Q2')}
            >
              Q2
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 'Q3' ? styles.selected : ''}`}
              onClick={() => setSelectedRange('Q3')}
            >
              Q3
            </div>
            <div
              className={`${styles.c2} ${selectedRange === 'Q4' ? styles.selected : ''}`}
              onClick={() => setSelectedRange('Q4')}
            >
              Q4
            </div>
            <div
              className={`${styles.c2} ${selectedRange === `Y${thisYear}` ? styles.selected : ''}`}
              onClick={() => setSelectedRange(`Y${thisYear}`)}
            >
              Bu Yıl
            </div>
            <GeneralDropdown
              formStyle="m-b-16 w-224"
              placeHolder="Ay Seçimi"
              dataList={goalsMonths}
              selected={selectedRange.startsWith('M') ? {
                key: selectedRange,
                value: goalsMonths.find((e) => e.key === selectedRange).value,
              } : null}
              setSelected={(e) => setSelectedRange(e.key)}
            />
            <div className="d-flex f1 justify-end">
              <div className={styles.btn} onClick={() => setSelectedTarget(targetModel)}>
                Hedef ekle
                <img alt="plus" src={icPlus} className="m-l-8" />
              </div>
              <img alt="excel" src={icExcel} className="c-pointer m-l-24" />
            </div>
          </div>
          {rangeData?.targets.length > 0 ? (
            <>
              <div>
                <div className={styles.c3}>
                  <div className={styles.c31}>
                    <div className={styles.tt1}>
                      <div>Kredi geliri (TL)</div>
                    </div>
                    <div className={`${styles.c32} m-t-16`}>
                      <div className={styles.tt2}>Gerçekleşme</div>
                      <div className={styles.tt3}>
                        {priceFormat(orderCountsData?.dashboardData?.totalCreditIncome || 0)}
                        {' '}
                        TL
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Hedef</div>
                      <div className={styles.tt3}>
                        {priceFormat(rangeData?.summary?.totalCreditIncome || 0)}
                        {' '}
                        TL
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Oran</div>
                      <div className={styles.tt31}>
                        %
                        {(((orderCountsData?.dashboardData?.totalCreditIncome || 0) / (rangeData?.summary?.totalCreditIncome || 0)) * 100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.c31} m-l-24`}>
                    <div className={styles.tt1}>
                      <div>Ciro (TL)</div>
                    </div>
                    <div className={`${styles.c32} m-t-16`}>
                      <div className={styles.tt2}>Gerçekleşme</div>
                      <div className={styles.tt3}>
                        {priceFormat(orderCountsData?.dashboardData?.creditAmount || 0)}
                        {' '}
                        TL
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Hedef</div>
                      <div className={styles.tt3}>
                        {priceFormat(rangeData?.summary?.totalCreditTurnover || 0)}
                        {' '}
                        TL
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Oran</div>
                      <div className={styles.tt32}>
                        %
                        {(((orderCountsData?.dashboardData?.creditAmount || 0) / (rangeData?.summary?.totalCreditTurnover || 0)) * 100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.c3}>
                  <div className={styles.c31}>
                    <div className={styles.tt1}>
                      <div>Üye iş yeri (Adet)</div>
                    </div>
                    <div className={`${styles.c32} m-t-16`}>
                      <div className={styles.tt2}>Gerçekleşme</div>
                      <div
                        className={styles.tt3}
                      >
                        {orderCountsData?.dashboardData?.totalMerchantAcquisitionCount || 0}
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Hedef</div>
                      <div className={styles.tt3}>
                        {rangeData?.summary?.totalMerchantAcquisition || 0}
                      </div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Oran</div>
                      <div className={styles.tt31}>
                        %
                        {(((orderCountsData?.dashboardData?.totalMerchantAcquisitionCount || 0) / (rangeData?.summary?.totalMerchantAcquisition || 0)) * 100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.c31} m-l-24`}>
                    <div className={styles.tt1}>
                      <div>Kullandırımdan düşenler (Adet)</div>
                    </div>
                    <div className={`${styles.c32} m-t-16`}>
                      <div className={styles.tt2}>İptal</div>
                      <div className={styles.tt3}>{getCount(7)}</div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>İade</div>
                      <div className={styles.tt3}>{getCount(5)}</div>
                    </div>
                    <div className={`${styles.c32} m-t-8`}>
                      <div className={styles.tt2}>Kısmi iade</div>
                      <div className={styles.tt3}>{getCount(6)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex f-column m-t-24">
                <div className={styles.tableTitle}>
                  <div>Yıl & Ay</div>
                  <div>Kredi gelir (TL)</div>
                  <div>Ciro (TL)</div>
                  <div>Üye iş yeri (Adet)</div>
                  <div />
                </div>
                <div className={styles.tableContent}>
                  {rangeData?.targets.map((item, index) => (
                    <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                      <div>{item?.monthYear}</div>
                      <div>{item?.creditIncomeGoal}</div>
                      <div>{item?.creditTurnoverGoal}</div>
                      <div>{item?.merchantAcquisitionGoal}</div>
                      <div>
                        <div className="d-flex align-center">
                          <div
                            className="c-pointer"
                            onClick={() => {
                              setSelectedTarget(item);
                            }}
                          >
                            <img alt="dot" src={icEdit} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.noResult}>
              <img alt="no-data" src={icNoResults} />
              <div className="m-t-24">Kayıtlı hedefiniz bulunmamaktadır.</div>
            </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const targetModel = {
  monthYear: '',
  creditIncomeGoal: 0,
  merchantAcquisitionGoal: 0,
  creditTurnoverGoal: 0,
};
export default Goals;
