import React, { useState } from 'react';
import './style.css';
import { GeneralButton } from '../../views/buttons/buttons';
import LoginHeader from '../../views/loginHeader';
import { GeneralInput } from '../../views/newInputs/inputs';
import { forgotPassword } from '../../../helpers/dataManager';

function ForgotPassword() {
  const [userName, setUserName] = useState('');
  function sendMail() {
    forgotPassword({ email: userName })
      .then((res) => { window.location.href = '/forgotPasswordSuccess'; })
      .catch(() => {});
  }
  return (
    <div className="mainLogin">
      <LoginHeader />
      <div
        className="loginCont"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            sendMail();
          }
        }}
      >
        <div className="loginMid">
          <div className="loginT1">Lütfen e-mail adresinizi giriniz!</div>
          <GeneralInput
            formStyle="m-t-32"
            value={userName}
            onChange={(e) => setUserName(e)}
            placeHolder="E-mail adresi"
          />
          <div className="loginT2 m-t-8">Sisteme kayıtlı e-mail adresinizi giriniz</div>
          <GeneralButton
            buttonStyle="login-bttn"
            buttonTitle="Şifremi Yenile"
            buttonClick={() => sendMail()}
          />
        </div>
      </div>
      <div className="loginBottom">
        <div>FinGate Kredi Paneli</div>
        <div>© 2025 | Tüm Hakları Saklıdır | FinGate</div>
      </div>
    </div>
  );
}
export default ForgotPassword;
