import React, { useEffect, useRef, useState } from 'react';
import {
  creditStatus, creditTypesEnum,
} from '../../../helpers/constant';
import {
  excel,
  getBanks, getOrderList, getSuperMerchants,
} from '../../../helpers/dataManager';
import { priceFormat } from '../../../utils/formatter';
import {
  GeneralDateRangePicker,
  GeneralInput,
  GeneralMultipleDropdown,
} from '../../views/newInputs/inputs';
import icSearch from '../../../assets/images/new/search.svg';
import icDetail from '../../../assets/images/new/detail.svg';
import Pagination from '../../views/pagination/pagination';
import icNoResults from '../../../assets/images/new/noResults.svg';
import BaseView from '../../views/baseView/baseView';
import { GeneralModal } from '../../views/modals/generalModal';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import icExcel from '../../../assets/images/new/excel.svg';
import filter1 from '../../../assets/images/new/Chips.svg';
import filter2 from '../../../assets/images/new/Chips-1.svg';

function SuperMerchant({ styles, statusCode }) {
  // Pagination
  const pageSize = 20;
  const pageNumber = useRef(1);

  // General
  const [selectedSection, setSelectedSection] = useState(1);
  const [orderSearch, setOrderSearch] = useState('');
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [mobileFilter, setMobileFilter] = useState(false);

  // Filters
  const [bankList, setBankList] = useState([]);

  const [subMerchantList, setSubMerchantList] = useState([]);
  const [selectedSubMerchant, setSelectedSubMerchant] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [selectedBanks, setSelectedBanks] = useState([]);

  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      getDropdownData();
      getMerchantBranches();
      getOrders();
    }
    currentRef.current = false;
  }, []);

  function getMerchantBranches() {
    getSuperMerchants()
      .then((res) => {
        setSubMerchantList(res.map((item) => ({ ...item, key: item.id, value: item.merchantName })));
      })
      .catch(() => {});
  }
  function getDropdownData() {
    getBanks()
      .then((res) => { setBankList(res.map((e) => ({ ...e, key: e.id, value: e.bankName }))); })
      .catch(() => {});
  }
  function getOrders() {
    getOrderList({
      orderId: orderSearch.length < 3 ? null : orderSearch,
      applicationStatusCodes: statusCode || null,
      startDate: filterStartDate,
      endDate: filterEndDate,
      merchants: selectedSubMerchant.map((e) => e.id),
      banks: selectedBanks.map((e) => e.id),
      integrationTypeId: null,
      creditType: null,
      page: pageNumber.current - 1,
      size: pageSize,
    })
      .then((res) => {
        setReportData(res);
      })
      .catch(() => {});
  }
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setFilterStartDate(start);
    setFilterEndDate(end);
  };
  function getCommissionLabel(rate, type) {
    if (type?.value === 'Percentage') {
      return `${rate}%`;
    } if (type?.value === 'Fixed') {
      return `${priceFormat(rate)} TL`;
    }
    return '';
  }
  function excelExport() {
    excel({
      body: {
        orderId: orderSearch.length < 3 ? null : orderSearch,
        applicationStatusCodes: statusCode || null,
        startDate: filterStartDate,
        endDate: filterEndDate,
        merchants: selectedSubMerchant.map((e) => e.id),
        banks: selectedBanks.map((e) => e.id),
        integrationTypeId: null,
        creditType: null,
      },
    }).then((res) => {
    })
      .catch(() => {});
  }
  function checkFilter() {
    return filterStartDate === null
            && filterEndDate === null
            && selectedBanks.length === 0
            && selectedSubMerchant.length === 0;
  }
  function renderSections() {
    switch (selectedSection) {
      case 1:
        return (
          <>
            {mobileFilter && (
            <div className={`${styles.mobileFilterBack} d-flex-small`}>
              <div className={styles.mbR} onClick={() => setMobileFilter(false)} />
              <div className={styles.mobileFilter}>
                <div className="f1 d-flex f-column">
                  <GeneralDateRangePicker
                    endDate={filterEndDate}
                    startDate={filterStartDate}
                    maxDate={new Date()}
                    minDate={new Date(2022, 1, 1)}
                    placeHolder="Tarih Seçimi"
                    formStyle="z1"
                    setDate={(e) => onChangeDate(e)}
                  />
                  <GeneralMultipleDropdown
                    dataList={bankList}
                    selected={selectedBanks}
                    formStyle="m-t-16 z2"
                    placeHolder="Finansal kurum"
                    setSelected={(e) => setSelectedBanks(e)}
                  />
                  <GeneralMultipleDropdown
                    formStyle="m-t-16 z3"
                    placeHolder="Bayi adı"
                    selected={selectedSubMerchant}
                    dataList={subMerchantList}
                    setSelected={(e) => setSelectedSubMerchant(e)}
                  />
                </div>
                <div className="d-flex f-column m-t-16 w100">
                  <div
                    className={`${styles.btn2} w100`}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Seçimleri Sıfırla
                  </div>
                  <div
                    className={`${styles.btn} w100 m-t-16`}
                    onClick={() => {
                      getOrders();
                      setMobileFilter(false);
                    }}
                  >
                    Filtrele
                  </div>
                </div>
              </div>
            </div>
            )}
            <div className="d-none-small f-column">
              <div className="d-flex f-column">
                <div className="m-t-16 d-flex z1">
                  <GeneralDateRangePicker
                    endDate={filterEndDate}
                    startDate={filterStartDate}
                    maxDate={new Date()}
                    minDate={new Date(2022, 1, 1)}
                    placeHolder="Tarih Seçimi"
                    setDate={(e) => onChangeDate(e)}
                    formStyle="w-300 m-r-16"
                  />
                  <GeneralInput
                    value={orderSearch}
                    rightIcon={icSearch}
                    onChange={(e) => setOrderSearch(e)}
                    formStyle={styles.searchBar}
                    placeHolder="İşlem ID"
                  />
                  <div className="f1 d-flex f-column align-end">
                    <div onClick={() => excelExport()} className="c-pointer">
                      <img alt="excel" src={icExcel} />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <GeneralMultipleDropdown
                    dataList={bankList}
                    formStyle="f1 m-r-16 m-t-16"
                    selected={selectedBanks}
                    placeHolder="Finansal kurum"
                    setSelected={(e) => setSelectedBanks(e)}
                  />
                  <GeneralMultipleDropdown
                    formStyle="f1 m-t-16"
                    placeHolder="Bayi adı"
                    selected={selectedSubMerchant}
                    dataList={subMerchantList}
                    setSelected={(e) => setSelectedSubMerchant(e)}
                  />
                </div>
              </div>
              <div className="d-flex align-center m-t-24 justify-end">
                <div
                  className={`${styles.btn2} m-r-24`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Seçimleri Sıfırla
                </div>
                <div className={styles.btn} onClick={() => getOrders()}>
                  Filtrele
                </div>
              </div>
            </div>
            <div className="d-flex-small justify-space-between">
              <div>
                <GeneralInput
                  value={orderSearch}
                  rightIcon={icSearch}
                  onChange={(e) => setOrderSearch(e)}
                  formStyle={`${styles.searchBar} m-t-0`}
                  placeHolder="İşlem ID"
                />
              </div>
              <div className="c-pointer" onClick={() => setMobileFilter(!mobileFilter)}>
                <img alt="filter" src={checkFilter() ? filter1 : filter2} />
              </div>
            </div>
            <div className="d-flex-small align-center m-t-16">
              <div
                className={`${styles.btn2} m-r-24`}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Seçimleri Sıfırla
              </div>
              <div className={styles.btn} onClick={() => getOrders()}>
                Filtrele
              </div>
            </div>
            {reportData?.content.length > 0
              ? (
                <>
                  <div className={styles.tableLayer}>
                    <div className={styles.tableLayer1}>
                      <div className={styles.tableTitle}>
                        <div>İşlem tarihi</div>
                        <div>İşlem ID</div>
                        <div>Kredi Tutarı</div>
                        <div>Banka</div>
                        <div>Üye İş Yeri</div>
                        <div>Kredi statusü</div>
                        <div>İade tutarı</div>
                        <div>Kredi türü</div>
                        <div />
                      </div>
                      <div className={styles.tableContent}>
                        {reportData?.content.map((item, index) => (
                          <div
                            key={index}
                            className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}
                          >
                            <div>{item?.orderDate}</div>
                            <div>{item?.orderId}</div>
                            <div>
                              {priceFormat(item?.amount)}
                              {' '}
                              TL
                            </div>
                            <div>{item?.bank?.bankName}</div>
                            <div>{item?.merchant?.merchantName}</div>
                            <div>{creditStatus[item?.applicationStatusCode]}</div>
                            <div>
                              {priceFormat(item?.refundAmount)}
                              {' '}
                              TL
                            </div>
                            <div>{creditTypesEnum[item?.bank?.creditType]}</div>
                            <div>
                              <div className="d-flex align-center">
                                <div className="c-pointer" onClick={() => setOrderDetailModal(item)}>
                                  <img alt="dot" src={icDetail} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="m-b-100 d-none-small">
                      <Pagination
                        onPageChange={(e) => {
                          pageNumber.current = e;
                          getOrders();
                        }}
                        totalPage={reportData?.totalPages}
                        currentPage={pageNumber.current}
                      />
                    </div>
                  </div>
                  <div className="d-flex m-b-100 d-flex-small">
                    <Pagination
                      onPageChange={(e) => {
                        pageNumber.current = e;
                        getOrders();
                      }}
                      totalPage={reportData?.totalPages}
                      currentPage={pageNumber.current}
                    />
                  </div>
                </>
              )
              : (
                <div className={styles.noResult}>
                  <img alt="no-data" src={icNoResults} />
                  <div className="m-t-24">Raporunuz bulunmamaktadır.</div>
                </div>
              )}
          </>
        );
      default:
        return <div />;
    }
  }

  return (
    <BaseView>
      <GeneralModal visible={orderDetailModal} toggle={() => setOrderDetailModal(null)}>
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>İşlem detayları</div>
            <div className="c-pointer" onClick={() => setOrderDetailModal(null)}>
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className="d-flex f1 f-column">
              <div>
                <div className={styles.t5}>
                  İşlem tarihi
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.orderDate}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  İşlem ID
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.orderId}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Kredi tutarı
                </div>
                <div className={styles.t6}>
                  {priceFormat(orderDetailModal?.amount)}
                  {' '}
                  TL
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Kısmi iade tutarı
                </div>
                <div className={styles.t6}>
                  {priceFormat(orderDetailModal?.refundAmount)}
                  {' '}
                  TL
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Finansal kurum
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.bank?.bankName}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Üye iş yeri
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.merchant?.merchantNameAlias || '-'}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Bayi
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.merchant?.merchantName}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Kredi statüsü
                </div>
                <div className={styles.t6}>
                  {creditStatus[orderDetailModal?.applicationStatusCode]}
                </div>
              </div>
            </div>
            <div className="d-flex f1 f-column">
              <div>
                <div className={styles.t5}>
                  Kredi türü
                </div>
                <div className={styles.t6}>
                  {creditTypesEnum[orderDetailModal?.bank?.creditType]}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Finansal kurum komisyon oranı
                </div>
                <div className={styles.t6}>
                  {getCommissionLabel(orderDetailModal?.bank?.commissionRate, orderDetailModal?.bank?.commissionType)}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Üye iş yeri komisyon oranı
                </div>
                <div className={styles.t6}>
                  {getCommissionLabel(orderDetailModal?.merchant?.commissionRate, orderDetailModal?.merchant?.commissionType)}
                </div>
              </div>
              <div>
                <div className={styles.t5}>
                  Entegrasyon türü
                </div>
                <div className={styles.t6}>
                  {orderDetailModal?.merchant?.integrationType?.value}
                </div>
              </div>
            </div>
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        {renderSections()}
      </div>
    </BaseView>
  );
}

export default SuperMerchant;
