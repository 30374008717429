import React from 'react';
import styles from './users.module.css';
import { sessionStorageKeys, userRoles } from '../../../helpers/constant';
import SuperUser from './SuperUser';
import SuperMerchant from './SuperMerchant';
import MerchantAdmin from './MerchantAdmin';

function Users() {
  const userRole = sessionStorage.getItem(sessionStorageKeys.userRole);
  if (userRole === userRoles.superUser) {
    return <SuperUser styles={styles} />;
  }
  if (userRole === userRoles.superMerchant) {
    return <SuperMerchant styles={styles} />;
  }
  if (userRole === userRoles.merchantAdmin) {
    return <MerchantAdmin styles={styles} />;
  }

  return <div />;
}

export default Users;
