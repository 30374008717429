import React, { useState } from 'react';
import './style.css';
import { GeneralButton } from '../../views/buttons/buttons';
import { toastStore } from '../../../context/globalStores';
import { showToastError } from '../../../context/actions/toastActions';
import { setUserMerchantCredential, setUserSessionId } from '../../../helpers/sessionManager';
import { getMerchant, login } from '../../../helpers/dataManager';
import LoginHeader from '../../views/loginHeader';
import { GeneralInput } from '../../views/newInputs/inputs';

function Login() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  function userLogin() {
    if (userName === '' || password === '') {
      toastStore.dispatch(showToastError({ text: 'Giriş bilgilerinizi kontrol edin.' }));
    } else {
      login({ username: userName, password })
        .then((userData) => {
          const r = (Math.random() + 1).toString(36).substring(2);
          setUserSessionId({
            sessionId: r,
            userRole: userData.userRole,
            userName: `${userData.name} ${userData.surname}`,
            merchantNameAlias: userData.merchantNameAlias,
            accessToken: userData.accessToken,
            refreshToken: userData.refreshToken,
            guid: userData.userGuid,
          });
          getMerchant({ userGuid: userData.userGuid })
            .then((res) => {
              setUserMerchantCredential({
                merchantId: res?.id,
                mainMerchantId: res?.mainMerchantId,
                merchantLogo: res?.merchantLogoUrl,
              });
              window.location.href = '/dashboard';
            })
            .catch(() => {});
        })
        .catch((err) => {});
    }
  }
  return (
    <div className="mainLogin">
      <LoginHeader />
      <div
        className="loginCont"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            userLogin();
          }
        }}
      >
        <div className="loginMid">
          <div className="loginT1">Hoş geldiniz!</div>
          <div className="loginT2 m-t-24">Kullanıcı Adı</div>
          <GeneralInput
            formStyle="m-t-8"
            value={userName}
            onChange={(e) => setUserName(e)}
          />
          <div className="loginT2 m-t-8">Şifre</div>
          <GeneralInput
            formStyle="m-t-8"
            inputType="password"
            value={password}
            onChange={(e) => setPassword(e)}
          />
          <div className="loginT3 c-pointer" onClick={() => { window.location.href = '/forgotPassword'; }}>
            Şifremi Unuttum
          </div>
          <GeneralButton
            buttonStyle="login-bttn"
            buttonTitle="Giriş yap"
            buttonClick={() => userLogin()}
          />
        </div>
      </div>
      <div className="loginBottom">
        <div>FinGate Kredi Paneli</div>
        <div>© 2025 | Tüm Hakları Saklıdır | FinGate</div>
      </div>
    </div>
  );
}
export default Login;
