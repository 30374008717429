import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { value } from 'lodash/seq';
import BaseView from '../../views/baseView/baseView';
import icExcel from '../../../assets/images/new/excel.svg';
import { GeneralDropdown } from '../../views/newInputs/inputs';
import {
  bankCount, creditSummary, getBanks, merchantCount, merchants, orderCountsByMonth,
} from '../../../helpers/dataManager';
import { months, monthsLong } from '../../../helpers/constant';
import { priceFormat } from '../../../utils/formatter';

Chart.register(CategoryScale);
function SuperUser({ styles }) {
  const [selectedRange, setSelectedRange] = useState(30);
  const [labelData, setLabelData] = useState(null);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [financialCompany, setFinancialCompany] = useState(null);
  const [businessPartner, setBusinessPartner] = useState(null);
  const [filterDateList, setFilterDateList] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [merchantC, setMerchantC] = useState(0);
  const [bankC, setBankC] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [chart1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);

  const percent = 40;
  const callRef = useRef(false);
  useEffect(() => {
    if (!callRef.current) {
      getMerchants();
      getMerchantCount();
      getBankCount();
      getBanksData();
    }
    callRef.current = true;
  }, []);

  useEffect(() => {
    // Chart1
    getCreditSummary({ bankId: financialCompany?.key, merchantId: null, section: 1 });
  }, [financialCompany]);
  useEffect(() => {
    // Chart2
    getCreditSummary({ bankId: null, merchantId: businessPartner?.key, section: 2 });
  }, [businessPartner]);
  useEffect(() => {
    handleDateChange(selectedRange);
  }, [selectedRange]);
  useEffect(() => {
    if (filterDateList.length > 0) {
      handleDateChange(30);
    }
  }, [filterDateList]);
  function prepareDropdown(summaryData, ratioData) {
    const list = summaryData.sort((a, b) => new Date(b.year, b.month, 1) - new Date(a.year, a.month, 1));
    const totalList = [];
    for (const i in list) {
      const indexData = ratioData.find((d) => String(d.year) === String(list[i].year) && String(d.month) === String(list[i].month));
      if (indexData) {
        totalList.push({ ...list[i], ratioData: indexData });
      }
    }
    setFilterDateList(totalList.map((d) => ({ ...d, key: d.month, value: `${monthsLong[d.month - 1]} ${d.year}` })));
  }
  function handleDateChange(range) {
    const thisMonth = new Date().getMonth() + 1;
    const thisYear = new Date().getFullYear();

    switch (range) {
      case 30:
        // eslint-disable-next-line no-case-declarations
        const i = filterDateList.findIndex((d) => d.month === thisMonth && d.year === thisYear);
        if (i > -1) {
          setLabelData(filterDateList[i]);
        } else {
          const d = {
            totalAmount: 0,
            totalBankIncome: 0,
            totalCount: 0,
            totalMerchantIncome: 0,
            byMonth: {
              approvalRatio: '%0',
            },
          };
          setLabelData(d);
        }
        break;
      case 12:
        // eslint-disable-next-line no-case-declarations
        const allYear = filterDateList.filter((e) => e.year === thisYear);
        if (allYear.length > 0) {
        // eslint-disable-next-line no-case-declarations
          let allRatio = 0;
          for (const i in allYear) {
            allRatio += Number(allYear[i]?.ratioData?.approvalRatio.split('%')[1]);
          }

          // eslint-disable-next-line no-case-declarations
          const all = {
            totalAmount: Number((allYear.reduce((accumulator, item) => accumulator + item.totalAmount, 0)).toFixed(2)),
            totalBankIncome: Number((allYear.reduce((accumulator, item) => accumulator + item.totalBankIncome, 0)).toFixed(2)),
            totalCount: allYear.reduce((accumulator, item) => accumulator + item.totalCount, 0),
            totalMerchantIncome: Number((allYear.reduce((accumulator, item) => accumulator + item.totalMerchantIncome, 0)).toFixed(2)),
            ratioData: {
              approvalRatio: `%${(allRatio / allYear.length).toFixed(2)}`,
            },
          };
          setLabelData(all);
        } else {
          const d = {
            totalAmount: 0,
            totalBankIncome: 0,
            totalCount: 0,
            totalMerchantIncome: 0,
            byMonth: {
              approvalRatio: '%0',
            },
          };
          setLabelData(d);
        }
        break;
      case 99:
        // eslint-disable-next-line no-case-declarations
        const allData = filterDateList;
        if (allData.length > 0) {
          // eslint-disable-next-line no-case-declarations
          let allDataRatio = 0;
          for (const i in allData) {
            allDataRatio += Number(allData[i]?.ratioData?.approvalRatio.split('%')[1]);
          }

          // eslint-disable-next-line no-case-declarations
          const allD = {
            totalAmount: Number((allData.reduce((accumulator, item) => accumulator + item.totalAmount, 0)).toFixed(2)),
            totalBankIncome: Number((allData.reduce((accumulator, item) => accumulator + item.totalBankIncome, 0)).toFixed(2)),
            totalCount: allData.reduce((accumulator, item) => accumulator + item.totalCount, 0),
            totalMerchantIncome: Number((allData.reduce((accumulator, item) => accumulator + item.totalMerchantIncome, 0)).toFixed(2)),
            ratioData: {
              approvalRatio: `%${(allDataRatio / allData.length).toFixed(2)}`,
            },
          };
          setLabelData(allD);
        } else {
          const d = {
            totalAmount: 0,
            totalBankIncome: 0,
            totalCount: 0,
            totalMerchantIncome: 0,
            byMonth: {
              approvalRatio: '%0',
            },
          };
          setLabelData(d);
        }
        break;
      default:
        setLabelData(range);
        break;
    }
  }
  function getMerchants() {
    merchants()
      .then((res) => {
        const arr = res.map((e) => ({ key: e.id, value: e.merchantName }));
        setMerchantList(arr);
      })
      .catch((err) => {});
  }
  function getBanksData() {
    getBanks()
      .then((res) => {
        const arr = res.map((e) => ({ key: e.id, value: e.bankName }));
        setBankList(arr);
      })
      .catch((err) => {});
  }
  function getMerchantCount() {
    merchantCount()
      .then((res) => {
        setMerchantC(res);
      })
      .catch((err) => {});
  }
  function getBankCount() {
    bankCount()
      .then((res) => {
        setBankC(res);
      })
      .catch((err) => {});
  }
  function getOrderCountsByMonth(summaryData) {
    orderCountsByMonth()
      .then((res) => {
        prepareDropdown(summaryData, res);
      })
      .catch((err) => {});
  }
  function getCreditSummary({ bankId, merchantId, section }) {
    creditSummary({ bankId, merchantId })
      .then((res) => {
        if (filterDateList.length === 0 && section === 1) {
          getOrderCountsByMonth(res);
        }
        prepareChartData({ data: res, section });
      })
      .catch((err) => {});
  }
  function prepareChartData({ data, section }) {
    const thisYear = new Date().getFullYear();
    let lastYearData = data.filter((e) => e.year === thisYear - 1);
    let thisYearData = data.filter((e) => e.year === thisYear);
    lastYearData = lastYearData.sort((a, b) => a.month - b.month);
    thisYearData = thisYearData.sort((a, b) => a.month - b.month);
    const allData = [...lastYearData, ...thisYearData];
    const chartDataCount = allData.map((e) => e.totalCount);
    const chartDataAmount = allData.map((e) => e.totalAmount);
    const chartDataMonth = allData.map((e) => e.month);
    if (section === 1) {
      setChart1({ count: chartDataCount, amount: chartDataAmount, month: chartDataMonth });
    } else if (section === 2) {
      setChart2({ count: chartDataCount, amount: chartDataAmount, month: chartDataMonth });
    }
  }
  return (
    <BaseView>
      <div className={styles.main}>
        <div className={styles.c1}>
          <div className={styles.c11}>
            <div className="d-flex align-center">
              <div
                className={`${styles.c2} ${selectedRange === 30 ? styles.selected : ''}`}
                onClick={() => {
                  setSelectedRange(30);
                  setSelectedFilterDate(null);
                }}
              >
                Bu Ay
              </div>
              <div
                className={`${styles.c2} ${selectedRange === 12 ? styles.selected : ''}`}
                onClick={() => {
                  setSelectedRange(12);
                  setSelectedFilterDate(null);
                }}
              >
                Bu Yıl
              </div>
              <div
                className={`${styles.c2} ${selectedRange === 99 ? styles.selected : ''}`}
                onClick={() => {
                  setSelectedRange(99);
                  setSelectedFilterDate(null);
                }}
              >
                Tümü
              </div>
            </div>
            <GeneralDropdown
              formStyle={styles.dateBtn}
              placeHolder="Tarih seçiniz"
              selected={selectedFilterDate}
              dataList={filterDateList}
              setSelected={(e) => {
                if (e === null) {
                  setSelectedRange(30);
                } else {
                  setSelectedRange(e);
                }
                setSelectedFilterDate(e);
              }}
            />
          </div>
          {/* <div className={styles.c12}>
            <img alt="excel" src={icExcel} />
          </div> */}
        </div>
        <div className={styles.c3}>
          <div className={styles.c31}>
            <div className={styles.t1}>Kredi Kullandırım</div>
            <div className={`${styles.c32} m-t-16`}>
              <div className={styles.t2}>Adeti</div>
              <div className={styles.t3}>{labelData?.totalCount || 0}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Tutar</div>
              <div className={styles.t3}>
                {priceFormat(labelData?.totalAmount.toFixed(2) || 0)}
                {' '}
                TL
              </div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Sepet ort.</div>
              <div className={styles.t3}>
                {priceFormat(((labelData?.totalAmount || 0) / (labelData?.totalCount || 1)).toFixed(2) || 0)}
                {' '}
                TL
              </div>
            </div>
          </div>
          <div className={`${styles.c311}`}>
            <div className={styles.t1}>Kredi Geliri</div>
            <div className={`${styles.c32} m-t-16`}>
              <div className={styles.t2}>Tutar</div>
              <div className={styles.t3}>
                {priceFormat(((labelData?.totalBankIncome || 0) + (labelData?.totalMerchantIncome || 0)).toFixed(2))}
                {' '}
                TL
              </div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Onay Oranı</div>
              <div className={styles.t3}>{labelData?.ratioData?.approvalRatio || '%0'}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Kredi başına ort. gelir</div>
              <div className={styles.t3}>
                {priceFormat((((labelData?.totalBankIncome || 0) + (labelData?.totalMerchantIncome || 0)) / (labelData?.totalCount || 1)).toFixed(2))}
                {' '}
                TL
              </div>
            </div>
          </div>
          <div className={`${styles.c31}`}>
            <div className={styles.t1}>Finansal Kurum Adetleri</div>
            <div className={`${styles.c32} m-t-16`}>
              <div className={styles.t2}>Üye İş Yeri</div>
              <div className={styles.t3}>{merchantC || 0}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Banka</div>
              <div className={styles.t3}>{bankC?.bankCount || 0}</div>
            </div>
            <div className={`${styles.c32} m-t-4`}>
              <div className={styles.t2}>Finansman şirketi</div>
              <div className={styles.t3}>{bankC?.financingCompanyCount || 0}</div>
            </div>
          </div>
        </div>
        {/* <div className={styles.c4}>
          <div className="d-flex justify-space-between w100">
            <div className={styles.t1}>Hedef Gerçekleşme Oranı</div>
            <div className={styles.t1}>
              %
              {percent}
            </div>
          </div>
          <div className="m-t-24 w100">
            <div className={styles.rangeBack}>
              <div style={{ width: `${percent}%` }} className={styles.range}>
                <div className={styles.balloon1}>
                  <div>
                    44 TL
                  </div>
                </div>
              </div>
              <div className={styles.balloon2}>
                1.965.444,00 TL
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.chartC}>
          {chart1 && (
          <div className={styles.chartBack}>
            <div className="d-flex justify-space-between">
              <div className="d-flex f-column">
                <div className={styles.t1}>Finansal kurum (Yıllık toplam)</div>
                <div
                  className={`${styles.t3} m-t-16`}
                >
                  {priceFormat(Number((chart1.amount.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2)))}
                  {' '}
                  TL
                </div>
                <div className={`${styles.t2} m-t-8`}>
                  {Number((chart1.count.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2))}
                  {' '}
                  Adet
                </div>
              </div>
              <GeneralDropdown
                placeHolder="Finansal Kurum Seçin"
                selected={financialCompany}
                formStyle="w-224"
                dataList={bankList}
                setSelected={(e) => setFinancialCompany(e)}
              />
            </div>
            {chart1 && (
            <div className={styles.graphCart}>
              <Bar
                data={{
                  labels: chart1.month.map((e) => months[e - 1]),
                  datasets: [{
                    type: 'line',
                    label: 'Adet',
                    data: chart1?.count || [],
                    backgroundColor: '#34A853',
                    borderColor: '#34A853',
                    yAxisID: 'y',
                    pointRadius: 0,
                    borderWidth: 2,
                  }, {
                    type: 'bar',
                    label: 'Tutar',
                    data: chart1?.amount || [],
                    backgroundColor: '#FBBD05',
                    borderRadius: 2,
                    barThickness: 32,
                    yAxisID: 'y1',
                  }],
                }}
                options={chartOptions}
              />
            </div>
            )}
          </div>
          )}
          {chart2 && (
          <div className={styles.chartBack}>
            <div className="d-flex justify-space-between">
              <div className="d-flex f-column">
                <div className={styles.t1}>İş ortağı (Yıllık toplam)</div>
                <div
                  className={`${styles.t3} m-t-16`}
                >
                  {priceFormat(Number((chart2.amount.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2)))}
                  {' '}
                  TL
                </div>
                <div className={`${styles.t2} m-t-8`}>
                  {Number((chart2.count.reduce((accumulator, item) => accumulator + item, 0)).toFixed(2))}
                  {' '}
                  Adet
                </div>
              </div>
              <GeneralDropdown
                placeHolder="İş Ortağı Seçin"
                selected={businessPartner}
                dataList={merchantList}
                formStyle="w-224"
                setSelected={(e) => setBusinessPartner(e)}
              />
            </div>
            {chart2 && (
            <div className={styles.graphCart}>
              <Bar
                data={{
                  labels: chart2.month.map((e) => months[e - 1]),
                  datasets: [{
                    type: 'line',
                    label: 'Adet',
                    data: chart2?.count || [],
                    backgroundColor: '#E94234',
                    borderColor: '#E94234',
                    yAxisID: 'y',
                    pointRadius: 0,
                    borderWidth: 2,
                  }, {
                    type: 'bar',
                    label: 'Tutar',
                    data: chart2?.amount || [],
                    backgroundColor: '#4285F4',
                    borderRadius: 2,
                    barThickness: 32,
                    yAxisID: 'y1',
                  }],
                }}
                options={chartOptions}
              />
            </div>
            )}
          </div>
          )}
        </div>
      </div>
    </BaseView>
  );
}

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
      position: 'right',
    },
    y1: {
      grid: { display: false },
    },
  },
  responsive: true,
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      labels: {
        usePointStyle: true,
        padding: 24,
        color: '#BDBDBD',
      },
      display: true,
      position: 'bottom',
      reverse: true,
    },
  },
};
export default SuperUser;
