import React, { useEffect, useRef, useState } from 'react';
import BaseView from '../../views/baseView/baseView';
import styles from './merchantBanks.module.css';
import {
  GeneralDropdown, GeneralInput,
} from '../../views/newInputs/inputs';
import icSearch from '../../../assets/images/new/search.svg';
import icPlus from '../../../assets/images/new/plus.svg';
import icDetail from '../../../assets/images/new/detail.svg';
import icCancel from '../../../assets/images/new/cancel-b.svg';
import { GeneralModal } from '../../views/modals/generalModal';
import { GeneralButton } from '../../views/buttons/buttons';
import {
  createMerchantBanks,
  deleteMerchantBanks,
  getBanks, getParameter, merchantBanks, merchants, updateMerchantBanks,
} from '../../../helpers/dataManager';
import Pagination from '../../views/pagination/pagination';
import { debounce } from '../../../utils/debounce';
import icNoResults from '../../../assets/images/new/noResults.svg';
import { creditTypesEnum, fieldLocalize } from '../../../helpers/constant';
import icDelete from '../../../assets/images/new/delete.svg';

function MerchantBanks() {
  // Pagination
  const pageSize = 20;
  const pageNumber = useRef(1);
  // General
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  //-------------------
  // Data List
  const [merchantBanksData, setMerchantBanksData] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [bankList, setBankList] = useState([]);
  //-------------------

  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      getParameterData();
    }
    currentRef.current = false;
  }, []);
  debounce(() => {
    pageNumber.current = 1;
    getMerchantBanksData();
  }, [searchQuery], 500);
  function getMerchantBanksData() {
    merchantBanks({ page: pageNumber.current - 1, size: pageSize, query: searchQuery.length < 3 ? '' : searchQuery })
      .then((res) => { setMerchantBanksData(res); })
      .catch((err) => {});
  }
  function getParameterData() {
    merchants(true)
      .then((res) => {
        const a = res.map((e) => ({ key: e.id, value: e.merchantNameAlias }));
        setMerchantList(a);
      })
      .catch(() => {});
    getBanks()
      .then((res) => {
        const a = res.map((e) => ({ key: `${e.bankCode}-${e.creditType}`, value: `${e.bankName} - ${creditTypesEnum[e.creditType]}` }));
        setBankList(a);
      })
      .catch(() => {});
    /* getParameter({ parameter: 'FirmType' })
      .then((res) => {
        const a = res.map((e) => ({ parameter: e, key: e.id, value: e.value }));
      })
      .catch(() => {}); */
  }
  function checkRequirements() {
    const selectedBank = bankFieldMap[selectedItem?.bankCode];
    if (selectedBank) {
      if (selectedItem?.merchantId === null
          || selectedItem?.bankCode === null) {
        return true;
      }
      const list = selectedBank.filter((e) => e.required === true);
      let r = false;
      for (const item in list) {
        if (selectedItem[list[item]?.key] === '') {
          r = true;
        }
      }
      return r;
    }
    return true;
  }
  function handleDataChange(key1, value) {
    const a = { ...selectedItem };
    a[key1] = value;
    setSelectedItem(a);
  }
  function handleDelete() {
    deleteMerchantBanks({ id: removeItem?.id })
      .then((res) => {
        setRemoveItem(null);
        getMerchantBanksData();
      })
      .catch(() => {});
  }
  function handleSave() {
    const b = {
      ...selectedItem,
      bankCode: selectedItem.bankCode.split('-')[0],
      creditType: selectedItem.bankCode.split('-')[1],
    };
    if (selectedItem?.id) {
      updateMerchantBanks({ body: b, id: selectedItem.id })
        .then((res) => {
          setSelectedItem(null);
          getMerchantBanksData();
        })
        .catch(() => {});
    } else {
      createMerchantBanks({ body: b })
        .then((res) => {
          setSelectedItem(null);
          getMerchantBanksData();
        })
        .catch(() => {});
    }
  }
  function getComponent(index) {
    const selectedBank = bankFieldMap[selectedItem?.bankCode];
    if (selectedBank && selectedBank.length >= index) {
      switch (selectedBank[index]?.type) {
        case 'freeText':
          return (
            <GeneralInput
              darkText
              value={selectedItem[selectedBank[index]?.key] || ''}
              onChange={(e) => handleDataChange(selectedBank[index]?.key, e)}
              placeHolder={fieldLocalize[selectedBank[index]?.key]}
              title={fieldLocalize[selectedBank[index]?.key]}
            />
          );
        default:
          return null;
      }
    }
    return null;
  }
  return (
    <BaseView>
      <GeneralModal
        toggle={() => {
          setRemoveItem(null);
        }}
        visible={removeItem}
      >
        <div className="d-flex f-column p-32 w-300">
          <div className="d-flex align-center">
            <div className="modal-content-title">Silme</div>
            <div
              onClick={() => {
                setRemoveItem(null);
              }}
              className="c-pointer"
            >
              <img alt="cancel" src={icCancel} />
            </div>
          </div>
          <div className="m-t-16 modal-content-text">
            {removeItem?.merchantName}
            {' - '}
            {removeItem?.bankName}
            {' '}
            eşleştirmesini silmek istiyor musunuz?
          </div>
          <GeneralButton
            buttonStyle="m-t-16"
            buttonTitle="SİL"
            buttonClick={() => {
              handleDelete();
            }}
          />
        </div>
      </GeneralModal>
      <GeneralModal
        visible={selectedItem !== null}
        toggle={() => {
          setSelectedItem(null);
        }}
      >
        <div className={styles.modal}>
          <div className="d-flex align-center justify-space-between">
            <div className={styles.title1}>Eşleştirme Detayı</div>
            <div
              className="c-pointer"
              onClick={() => {
                setSelectedItem(null);
              }}
            >
              <img alt="close" src={icCancel} />
            </div>
          </div>
          <div className="m-t-8 d-flex f-column">
            <div className={styles.modalRow}>
              <div className={styles.modalRowItem1}>
                <GeneralDropdown
                  isLight
                  isPassive={!!selectedItem?.id}
                  search
                  title="Firma"
                  placeHolder="Firma"
                  dataList={merchantList}
                  formStyle="z1"
                  selected={{
                    key: selectedItem?.merchantId,
                    value: merchantList.find((e) => e?.key === selectedItem?.merchantId)?.value,
                  }}
                  setSelected={(e) => handleDataChange('merchantId', e?.key)}
                />
              </div>
              <div className={styles.modalRowItem2}>
                <GeneralDropdown
                  isLight
                  search
                  isPassive={!!selectedItem?.id}
                  title="Banka"
                  placeHolder="Banka"
                  dataList={bankList}
                  formStyle="z1"
                  selected={{
                    key: selectedItem?.bankCode,
                    value: bankList.find((e) => e?.key === selectedItem?.bankCode)?.value,
                  }}
                  setSelected={(e) => handleDataChange('bankCode', e?.key)}
                />
              </div>
            </div>
            <div className={styles.modalRow}>
              {getComponent(0) && (
              <div className={styles.modalRowItem1}>
                {getComponent(0)}
              </div>
              )}
              {getComponent(1) && (
              <div className={styles.modalRowItem2}>
                {getComponent(1)}
              </div>
              )}
            </div>
            <div className={styles.modalRow}>
              {getComponent(2) && (
              <div className={styles.modalRowItem1}>
                {getComponent(2)}
              </div>
              )}
              {getComponent(3) && (
              <div className={styles.modalRowItem2}>
                {getComponent(3)}
              </div>
              )}
            </div>
            <div className={styles.modalRow}>
              {getComponent(4) && (
              <div className={styles.modalRowItem1}>
                {getComponent(4)}
              </div>
              )}
              {getComponent(5) && (
              <div className={styles.modalRowItem2}>
                {getComponent(5)}
              </div>
              )}
            </div>
            <div className={styles.modalRow}>
              {getComponent(6) && (
              <div className={styles.modalRowItem1}>
                {getComponent(6)}
              </div>
              )}
              {getComponent(7) && (
              <div className={styles.modalRowItem2}>
                {getComponent(7)}
              </div>
              )}
            </div>
            <div className={styles.modalRow}>
              {getComponent(8) && (
              <div className={styles.modalRowItem1}>
                {getComponent(8)}
              </div>
              )}
              {getComponent(9) && (
              <div className={styles.modalRowItem2}>
                {getComponent(9)}
              </div>
              )}
            </div>
            <div className={styles.modalRow}>
              {getComponent(10) && (
              <div className={styles.modalRowItem1}>
                {getComponent(10)}
              </div>
              )}
              {getComponent(11) && (
              <div className={styles.modalRowItem2}>
                {getComponent(11)}
              </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-center m-t-24">
            <GeneralButton
              isGhost={checkRequirements()}
              buttonTitle="Kaydet"
              buttonStyle="w50"
              buttonClick={() => {
                handleSave();
              }}
            />
          </div>
        </div>
      </GeneralModal>
      <div className={styles.main}>
        <div className="d-flex align-center">
          <GeneralInput
            value={searchQuery}
            rightIcon={icSearch}
            onChange={(e) => setSearchQuery(e)}
            formStyle={styles.searchBar}
            placeHolder="Banka, Üye işyeri..."
          />
          <div
            className={styles.btn}
            onClick={() => {
              setSelectedItem(JSON.parse(JSON.stringify(merchantBanksModel)));
            }}
          >
            Yeni Bağlantı Ekle
            <img alt="plus" src={icPlus} className="m-l-8" />
          </div>
        </div>
        {merchantBanksData?.content?.length > 0 ? (
          <>
            <div className="d-flex f-column m-t-24">
              <div className={styles.tableTitle}>
                <div>Banka</div>
                <div>Üye İşyeri</div>
                <div>Minimum Kredi Limiti</div>
                <div>Maksimum Kredi Limiti</div>
                <div />
              </div>
              <div className={styles.tableContent}>
                {merchantBanksData?.content.map((item, index) => (
                  <div key={index} className={`${styles.tableRow} ${(index % 2 === 1) && styles.tableRow1}`}>
                    <div>{item?.bankName}</div>
                    <div>{item?.merchantName}</div>
                    <div>{item?.minCreditAmount}</div>
                    <div>{item?.maxCreditAmount}</div>
                    <div>
                      <div className="d-flex align-center">
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setSelectedItem(JSON.parse(JSON.stringify({
                              ...item,
                              bankCode: `${item?.bankCode}-${item?.creditType}`,
                            })));
                          }}
                        >
                          <img alt="dot" src={icDetail} />
                        </div>
                        <div
                          className="c-pointer"
                          onClick={() => {
                            setRemoveItem(item);
                          }}
                        >
                          <img alt="dot" src={icDelete} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="m-b-100">
              <Pagination
                onPageChange={(e) => {
                  pageNumber.current = e;
                  getMerchantBanksData();
                }}
                totalPage={merchantBanksData?.totalPages}
                currentPage={pageNumber.current}
              />
            </div>
          </>
        ) : (
          <div className={styles.noResult}>
            <img alt="no-data" src={icNoResults} />
            <div className="m-t-24">Sonuç bulunmamaktadır.</div>
          </div>
        )}
      </div>
    </BaseView>
  );
}

const bankFieldMap = {
  '064-SHOPPING_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '067-SHOPPING_CREDIT': [
    { key: 'channel', type: 'freeText', required: true },
    { key: 'timeToLive', type: 'freeText', required: true },
    { key: 'posTerminalNumber', type: 'freeText', required: true },
    { key: 'posFirmNumber', type: 'freeText', required: true },
    { key: 'salt', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '062-SHOPPING_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'appKey', type: 'freeText', required: true },
    { key: 'posTerminalNumber', type: 'freeText', required: true },
    { key: 'posFirmNumber', type: 'freeText', required: true },
    { key: 'salt', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '046-SHOPPING_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'subDealer', type: 'freeText', required: true },
    { key: 'appKey', type: 'freeText', required: true },
    { key: 'channel', type: 'freeText', required: true },
    { key: 'timeToLive', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '034-SHOPPING_CREDIT': [
    { key: 'channel', type: 'freeText', required: true },
    { key: 'timeToLive', type: 'freeText', required: true },
    { key: 'posTerminalNumber', type: 'freeText', required: true },
    { key: 'posFirmNumber', type: 'freeText', required: true },
    { key: 'salt', type: 'freeText', required: false },
    { key: 'interestRate', type: 'freeText', required: false },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '032-SHOPPING_CREDIT_PRE_APPROVED': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'subDealer', type: 'freeText', required: true },
    { key: 'timeToLive', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '206-SHOPPING_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '205-SHOPPING_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
  '064-COMMERCIAL_CREDIT': [
    { key: 'clientId', type: 'freeText', required: true },
    { key: 'clientSecret', type: 'freeText', required: true },
    { key: 'dealer', type: 'freeText', required: true },
    { key: 'subDealer', type: 'freeText', required: true },
    { key: 'appKey', type: 'freeText', required: true },
    { key: 'channel', type: 'freeText', required: true },
    { key: 'interestRate', type: 'freeText', required: true },
    { key: 'notificationUrl', type: 'freeText', required: false },
    { key: 'minCreditAmount', type: 'freeText', required: false },
    { key: 'maxCreditAmount', type: 'freeText', required: false },
  ],
};
const merchantBanksModel = {
  merchantId: null,
  bankCode: null,
  creditType: '',
  clientId: '',
  clientSecret: '',
  subDealer: '',
  dealer: '',
  appKey: '',
  channel: '',
  notificationUrl: '',
  timeToLive: 3600,
  posTerminalNumber: '',
  posFirmNumber: '',
  salt: '',
  notificationApikey: '',
  interestRate: '',
  minCreditAmount: '',
  maxCreditAmount: '',
};

export default MerchantBanks;
